// order-details.state.ts

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderRequestDetail, OrderRequestDetailData } from '../../../interfaces/tabel-data';

// Define the state interface
export interface OrderDetailsState {
  orderRequestData: OrderRequestDetail[];
}

// Initial state
export const initialOrderDetailsState: OrderDetailsState = {
  orderRequestData: [],
};

// Create selectors
const selectOrderDetailsFeature = createFeatureSelector<OrderDetailsState>('orderDetails');

export const selectOrderDetails = createSelector(
  selectOrderDetailsFeature,
  (state) => state.orderRequestData
);
