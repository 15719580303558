import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth/auth.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginError = false
  isLogged = false
  constructor(
    private activatedRoute: ActivatedRoute,
    private authse: AuthService,
    private route: Router,
    public _snackBar:MatSnackBar
  ) { 
    if (localStorage.getItem('token')) {
      this.isLogged = true
      if (localStorage.getItem('passwordUpdateRequired') === '1'){
        this.route.navigate(['change-password'])
      } else if (localStorage.getItem('roleId') === '1' && localStorage.getItem('role') == 'Administrator') {
        this.route.navigate(['admin'])
      } else if (localStorage.getItem('roleId') === '2' && localStorage.getItem('role') == 'Vendor') {
        this.route.navigate(['merchants'])
      } 
    } else {
      this.isLogged = false
    }

  }

  // vars
  path: any = "";
  formName: undefined | string = "login";
  modalStatus: boolean = false;
  logginMSG: string = '';
  // crossDisable:boolean = true;
  passwordLoginType: boolean = true
  loginDetails = new FormGroup({
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
    ]),
    password:new FormControl('')
  })

  ngOnInit(): void {
    this.path = this.activatedRoute.snapshot.routeConfig?.path;
    if (this.path == 'login') {
      this.formName = 'login'
    } else if (this.path == 'forgot-password') {
      this.formName = 'forgot-password'
    }    
  }
  loadingClass = false
  snackbarColor = 'snackbar_green'
  userLogin(email: string, password: string) {
    
    if(!email  || !password){
      this.loginError = true
      return
    }
    this.loginError = false
    this.modalStatus = true;
    this.logginMSG = ''
    this.loadingClass = true;
    // console.log(22);
    
    this.authse.userLogin(email, password).subscribe((response: any) => {
      const apiResponse = response;
      if (apiResponse.body.status_code !== 200) {
        this.loadingClass = false
        this.snackbarColor = 'snackbar_red'
      } else if(apiResponse.body.status_code === 200){
        this.loadingClass = false
        this.snackbarColor = 'snackbar_green'
      } else {
        this.loadingClass = false
        this.snackbarColor = 'snackbar_red'
      }      
      this.logginMSG = apiResponse.body.message;
      this._snackBar.open(this.logginMSG,"",{
        duration: 1000, verticalPosition: 'top', horizontalPosition: 'end',
        panelClass :[this.snackbarColor]
      })
    });
  }

  resetPassword(email: any) {
    if(!email){
      this.loginError = true
      return
    }
    this.loadingClass = true;
    this.modalStatus = true;
    this.logginMSG = ''
    this.authse.resetPassword(email).subscribe((response: any) => {
      const apiResponse = response.body;
      
      this.loadingClass = false
      this.logginMSG = apiResponse.message;
      if (apiResponse.status === "success") {        
        this.route.navigate(['login']);  
        this._snackBar.open(this.logginMSG,"",{
          duration: 1000, verticalPosition: 'top', horizontalPosition: 'end',
          panelClass : ['snackbar_green']
        })      
      }
      else{
        this._snackBar.open(this.logginMSG,"",{
          duration: 1000, verticalPosition: 'top', horizontalPosition: 'end',
          panelClass : ['snackbar_red']
        })
      }
      
    });
  }

  modalToggle() {
    this.modalStatus = !this.modalStatus;
  }
  toggleVisiblity() {
    this.passwordLoginType = !this.passwordLoginType;
  }
}
