import { Component, Input, Output,EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsService } from '../../core/services/products/products.service';

@Component({
  selector: 'app-mapped-notselling-product',
  templateUrl: './mapped-notselling-product.component.html',
  styleUrls: ['./mapped-notselling-product.component.scss']
})
export class MappedNotsellingProductComponent {
  @Input() productItemData! : any;
  @Input() vendorID ! : any;
  @Input() Suggested_effective_date ! : Date;
  @Output() newItemEvent = new EventEmitter<string>();
  today = new Date();
  mapeffectiveDate!: Date | null ;
  passEventToParent(value: string) {
    this.newItemEvent.emit(value);
  }
  dateValid = true;
  isDateValidOnSubmit = false;
  formatValidate($event: any) {
    // console.log($event);
    if ( $event){
      // console.log($event?.value);
      this.isDateValidOnSubmit = false; 
      this.dateValid = true
    }
    else{
      this.dateValid = false;
     
    }
  }
  adminAppStatus = localStorage.getItem('roleId') ==='1';
  constructor(private ProductsService: ProductsService, public _snackBar: MatSnackBar,) { }
  AddPriceModalStatus = false;
  addedpricenew ! : number | null;
  toggleAddPriceModal(){
    this.AddPriceModalStatus = !this.AddPriceModalStatus;
    this.addedpricenew = null;
  }
  hasError = false;
  erpPricetoMap !: number;
  submitErpSellingInfo(){

    if (!this.mapeffectiveDate  && localStorage.getItem('roleId') === '1' && !this.addedpricenew){
      this.isDateValidOnSubmit = true;
      this.hasError = true;
      return
    }
    else if (!this.addedpricenew){
      this.hasError = true;
      return
    }
    else if (!this.mapeffectiveDate  && localStorage.getItem('roleId') === '1'){
      this.isDateValidOnSubmit = true;
      return
    }
    this.erpPricetoMap = this.addedpricenew; 
    localStorage.getItem('roleId') ==='1' ? this.vendorID = this.vendorID : this.vendorID = Number(localStorage.getItem('hashid'))
    this.requestErpToMapWithPrice()
  }
  closeModalOnbackdrop(){
    this.AddPriceModalStatus = false;
  }
  clearDate(){
    this.mapeffectiveDate = null;
  }
  effectiveDateSelection(event: any): any {
    const date = new Date(event);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; 
    const day = date.getDate(); 

     return `${year}-${month}-${day}`;
  }
  requestErpToMapWithPrice(){
 
    if (this.mapeffectiveDate){
      this.mapeffectiveDate = this.effectiveDateSelection(this.mapeffectiveDate)
    }
    localStorage.getItem('roleId') ==='1' ? 
    this.ProductsService.adminRequestProductMapMerchantwithPrice(this.vendorID, this.erpPricetoMap, this.productItemData?.id, this.productItemData?.id, this.mapeffectiveDate).subscribe((data: any) => {
      // console.log(data);
      if (data.status == 'success') {
        this.passEventToParent("sucess from child")
        
        this.AddPriceModalStatus = false;
        this._snackBar.open(data.message, "", {
          duration: 2600, verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_green']
        })
        
      } 
      else {
        this.AddPriceModalStatus = false;
        this._snackBar.open(data.message, "", {
          duration: 2000,verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_red']
        })
       
      }
      this.mapeffectiveDate = null;
    })
    :

    this.ProductsService.requestProductMapMerchantwithPrice(this.vendorID, this.erpPricetoMap, this.productItemData?.id, this.productItemData?.id).subscribe((data: any) => {
      // console.log(data);
      if (data.status == 'success') {
        this.passEventToParent("sucess from child")
      
        this.AddPriceModalStatus = false;
        this._snackBar.open(data.message, "", {
          duration: 2600, verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_green']
        })
       
      } 
      else {
        this.AddPriceModalStatus = false;
        this._snackBar.open(data.message, "", {
          duration: 2000,verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_red']
        })
       
      }
    })
  }
}
