import { Component, Input } from '@angular/core';
import { ProductsService } from '../../core/services/products/products.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ngxCsv } from 'ngx-csv';
import { CommonService } from '../../core/services/common.service';
@Component({
  selector: 'app-product-import-modal',
  templateUrl: './product-import-modal.component.html',
  styleUrls: ['./product-import-modal.component.scss']
})
export class ProductImportModalComponent {
  @Input() vendor_id!: number
  importModalStatus: boolean = false;
  selectedFile: File | undefined;
  productUploadedStatus: string = '';
  selectedFilepath: string = '';

  uploadMSG = '';
  isSuccess = false;
  productCount = 0
  closeModal() {
    this.importModalStatus = false;
  }
  importModalToggle(): void {
    this.importModalStatus = !this.importModalStatus;
    // this.productUploadedStatus = '';
    this.productUploadedStatus = '';
  }
  constructor(
    public products: ProductsService,
    private _snackBar: MatSnackBar,
    private _CommonService: CommonService,

  ) { }

  onFileSelected(event: any, dragged?: any) {
    // console.log(event);

    if (dragged) {
      this.selectedFile = event

      return
    }

    this.selectedFile = event.target.files[0] as File;
  }

  fileDropped(event: any) {
    event.preventDefault()
    // console.log("testttt");
    let item = event.dataTransfer.files[0] as File

    // console.log(item);
    this.selectedFilepath = item.name
    this.onFileSelected(item, true)
  }

  fileDragOver(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  uploadingProducts = false
  uploadProducts(data: any) {
    

    if (!this.selectedFile) {
      console.error('No file selected.');
      this._snackBar.open("Please Select File to upload", '', {
        duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
        panelClass: ['snackbar_red']
      })
      return;
    }
    this.uploadingProducts = true
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('vendor_id', String(this.vendor_id));


    this.products.importVendorProducts(formData).subscribe({
      next:(result: any) => {
        // console.log(result);
        this.productUploadedStatus = result.body.message;
  
        this.uploadMSG = result.body.message;
        if (result.body.status == "success") {
          this.isSuccess = true;
          this.productCount = result.body.result.sync_count
        }
        if (result.body.status == "error") {
          this.isSuccess = false;
          this.productCount = 0
        }
  
        setTimeout(() => {
         
          this.selectedFile = undefined;
          this.selectedFilepath = '';
         
        }, 4000);
      },
      error:(error)=>{
        this._CommonService.errorHandler(error)
      },
      complete:()=>{
        this.uploadingProducts = false
      }
    });

  }



  exportSampleCsv() {
    const options = { 
      headers: ["product_id", "sku", "erp_tsi_code", "price", "effective_date"]
    };
    const sampleJson = [
      {
        "product_id": 2263,
        "sku": "130802-1408",
        "erp_tsi_code": 130802,
        "price": 21,
        "effective_date": "22-09-2023"
      },
      {
        "product_id": 2262,
        "sku": "130815-1408",
        "erp_tsi_code": 130815,
        "price": 30,
        "effective_date": "22-09-2023"
      },
      {
        "product_id": 2241,
        "sku": "130815-1408",
        "erp_tsi_code": 130734,
        "price": 6,
        "effective_date": "22-09-2023"
      }
    ]

    return new ngxCsv(sampleJson, 'sample_product_list', options);

  }


}
