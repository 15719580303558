import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { MerchantProductMappingComponent } from './merchant-product-mapping/merchant-product-mapping.component';
import { ProductListComponent } from './product-list/product-list.component';


const routes: Routes = [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      }, 
      {
        path: 'list',
        component: ProductListComponent,
      },
      {
        path: 'review-mapping',
        component: MerchantProductMappingComponent,
      },
      {
        path: ':product_id/detail',
        component: ProductDetailComponent
      }
    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsRoutingModule {}
