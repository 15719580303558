import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '../../../../environments/api_url';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailService {

  constructor(
    private http :HttpClient
  ) { }

  getProductDetail(id:number, vendorId ?: number){
    const url = api.URL + '/api/product-detail-page-by-id';
    let payload ={
      product_id: id,
      vendor_id : vendorId
    }
    return this.http.post(url,payload )
  }
}
