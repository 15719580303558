// order-details.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { initialOrderDetailsState } from './order-details.state';
import * as OrderDetailsActions from './order-details.actions';

export const orderDetailsReducer = createReducer(
  initialOrderDetailsState,
  on(OrderDetailsActions.setOrderDetails, (state, { orderRequestData }) => ({
    ...state,
    orderRequestData,
  }))
);
