import { Component, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { ProductsService } from '../../core/services/products/products.service';
import { VendorProductItem } from '../../interfaces/vendor-data';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ProductImportModalComponent } from '../product-import-modal/product-import-modal.component';
import { ProductsMappingPopupsComponent } from '../products-mapping-popups/products-mapping-popups.component';
import { ActivatedRoute } from '@angular/router';
import { AddingSingleProductComponent } from '../adding-single-product/adding-single-product.component';
import { catchError, of } from 'rxjs';
import { ProductSellingConfirmationComponent } from '../product-selling-confirmation/product-selling-confirmation.component';

import { ProductCancelRequestComponent } from '../product-cancel-request/product-cancel-request.component';
import {FormGroup, FormControl} from '@angular/forms';
import { NonerpReviewMappingComponent } from '../nonerp-review-mapping/nonerp-review-mapping.component';
import { MappedNotsellingProductComponent } from '../mapped-notselling-product/mapped-notselling-product.component';
import { CommonService } from '../../core/services/common.service';
@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  animations:[
    trigger('slideDown', [
      state('hidden', style({ height: '166px', opacity: 1, overflow: 'hidden' })),
      state('visible', style({ height: '*', opacity: 1, overflow: 'hidden' })),
      transition('hidden => visible', animate('300ms ease-in')),
      transition('visible => hidden', animate('300ms ease-out')),
    ]),
  ]
})
export class ProductListComponent {
  isContentVisible: string = 'hidden'; // Few Animations 
  toggleContentVisibility() {
    this.isContentVisible = this.isContentVisible === 'hidden' ? 'visible' : 'hidden';
  }
  @ViewChild(ProductsMappingPopupsComponent) productMappingPopupsComponent!: ProductsMappingPopupsComponent;
  @ViewChild(ProductImportModalComponent) productImportModalComponent!: ProductImportModalComponent;
  @ViewChild(AddingSingleProductComponent) addingSingleProductComponent! :AddingSingleProductComponent;
  @ViewChild(ProductSellingConfirmationComponent) productSellingConfirmationComponent ! : ProductSellingConfirmationComponent;
  @ViewChild(ProductCancelRequestComponent) productCancelRequestComponent !: ProductCancelRequestComponent;
  @ViewChild(NonerpReviewMappingComponent) nonerpReviewMappingComponent !: NonerpReviewMappingComponent;
  @ViewChild(MappedNotsellingProductComponent) mappedNotsellingProductComponent! : MappedNotsellingProductComponent
  constructor(public products: ProductsService, private activatedRoute: ActivatedRoute, public _CommonService: CommonService,
    ) { 
    if (localStorage.getItem('roleId') !== '1') {
      this.userName = localStorage.getItem('fullName')
    } 
  }
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  mappingURL = ''
  pathnew:number | undefined;
  path = 0;  
  flagstatus!: number;
  
  ProductItems: VendorProductItem[] = [];
  mappingStats:boolean = false;
  loadingProduct = true;
  VendorAppStatus = localStorage.getItem('roleId') ==='2'; 
  dataPageNo = 1;
  IdInVendorDetail! : number;
  payload: any = {
    keyword: '',
    status: [],
  };
  hasChildEvent = false;
  changePriceProductInfo = {
    product_id : 0,
    price: 0,
    sku : '',
    erp_tsi_code: ''
  }
  sellingProductInfo ={
    product_id : 0,
    sku : '',
    product_name :''
  }
  cust_sorty_by =[
    {
      order_by : 'asc',
      sort_by :'name',
      sort_by_name : 'A to Z'
    },
    {
      order_by : 'desc',
      sort_by :'name',
      sort_by_name : 'Z to A'
    },
    {
      order_by : 'desc',
      sort_by :'id',
      sort_by_name : 'Newest to Oldest'
    },
    {
      order_by : 'asc',
      sort_by :'id',
      sort_by_name : 'Oldest to Newest'
    }
  ]
  chgPricePrdName= '';
  producPrice = 0;
  selectedDateRange: any = {};
  vendorType = localStorage.getItem('vendorType') === '1' ? 'nonerp' : ( localStorage.getItem('vendorType') === '2' ? 'erp': "admin" )
  vendorTypeInAdmin! : number;
  productStatus: string[] = (this.vendorType === 'nonerp') || (this.vendorTypeInAdmin == 1 )?  ['Selling', 'Not Selling' ] :['Mapped', 'Unmapped'] ;
  userName: string | null = '';
  clearButtonStatus = false;
  cancelEvent !: number;
  cancelEventID ! : number;
  totalMappedCount !: number
  timeoutId:any;
  notSellingmappedProduct ! :VendorProductItem;
  eventFromChild(newItem: string) {
    this.hasChildEvent = true;
    // console.log(newItem)
    this.getProducts(); 
      
  }
  openFilterDock = false 
  showFilters(){
    this.openFilterDock = true
  }
  hideFilters(){
    this.openFilterDock = false
  }
  clearDate(){
    this.range.reset();
    this.payload.daterange_filter = null;
    this.updateFilters();
    this.clearButtonStatus = false;
  }
  
  onCalenderClose(){
    this.clearButtonStatus = false;
    // console.log("close ", this.range.value.start, this.range.value.end)
    if(!(this.range.value.start && this.range.value.end) ){
      this.range.reset()
    }
   if (this.range.value.start || this.range.value.end)  {
      this.clearButtonStatus = true;
   }
  }
  logDateRange(date: string, type: boolean) {
    // console.log(date, type);
    if (date) {
      date = this._CommonService.formatDate(date)
      type ? this.selectedDateRange.start = date : this.selectedDateRange.end = date;
      !type ? this.payload.daterange_filter = this.selectedDateRange.start + " - " + this.selectedDateRange.end : '';
    }

  }

 setMappingUrl(){
    this.mappingURL = `../review-mapping`
  }
  openImportModal() {
    this.productImportModalComponent.importModalToggle();
  }

  openSellingModal(product_id: number, sku: string , product_name : string){
    this.sellingProductInfo.product_id = product_id;
    this.sellingProductInfo.sku = sku;
    this.sellingProductInfo.product_name =  product_name;
    this.productSellingConfirmationComponent.modalToggle();
  }

  OpenMappingPopup(flagstatus: number, productId:number, price:number, sku:string, erp_tsi_code:string, productName:string) {
    this.productMappingPopupsComponent.productMappingModalToggle();
    this.flagstatus = flagstatus;
    this.changePriceProductInfo.product_id = productId;
    this.changePriceProductInfo.price = price;
    this.changePriceProductInfo.sku = sku;
    this.changePriceProductInfo.erp_tsi_code = erp_tsi_code;
    this.chgPricePrdName = productName;
    this.producPrice =price; 
  }
  getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }
  mapProductList (vendorProductList : any[] ) :VendorProductItem[] {
    return vendorProductList.map((item:any) =>{
      return({
        id: item.id,
        product_name:item.name,
        product_id: item.id,
        updated_at: item.updated_at,
        sku: item.sku,
        erp_tsi_code: item.erp_tsi_code,
        price:item.price,
        vendor_price: item?.vendor_products[0]?.price,
        status:item.status,
        vendor_products: item.vendor_products,
        url : item.url,
        magento_id: item.magento_id,
        hasRequest : item?.has_vendor_product_request,
        hasmappedRequest : item?.has_mapped_request,
        mappedrequestedPrice : item?.latest_vendor_mapped_product_request?.price,
        requestedPrice : item?.latest_vendor_product_request?.price,
        mappedlaunchRequestedAt: item?.latest_vendor_mapped_product_request?.created_at,
        priceChangeRequestedAt:  item?.latest_vendor_product_request?.created_at,
        erp_vendor_products: item?.vendor_products[0]?.erp_vendor_products,
        // ismappedbNotSelling :   (this.getRandomInt(3)== 0)? true : false, 
          ismappedbNotSelling : false,
         // added for mapped but not selling feature 
      })
     
    })
  }

  nonErpReviewMapping(){
    this.nonerpReviewMappingComponent.modaltoggle();
  }
  getUpdateListRequest( updateMsg : string){
    this.hasChildEvent = true;
    this.getProducts();
    
  }

  openRequesCancelPopup(cancelEvent : number,cancelEventID :number ){
   
    this.cancelEvent = cancelEvent;
    this.cancelEventID = cancelEventID;
    this.productCancelRequestComponent.modalToggle();
  }

  getEventFromPopup(requestType :string){
    if ( this.cancelEvent === 0){
      this.productCancelRequestComponent.cancelPriceRequest(this.cancelEventID)
    }
    else if (this.cancelEvent === 1){
      this.productCancelRequestComponent.cancelMappingRequest(this.cancelEventID)
    }
  }
 
  suggested_effective_date ! :Date;

  getProducts(loadMoreDetails? :number) {
    
    loadMoreDetails ? this.payload.page = loadMoreDetails : (this.payload.page = 1, this.dataPageNo = 1, this.hasChildEvent ? this.ProductItems = this.ProductItems : this.ProductItems =[])

    this.path !== 0 &&  localStorage.getItem('roleId') ==='1' ? 
    this.products.getVendorProducts(this.path, this.payload).pipe(
      catchError(err => {
        throw(`${err}`)
      })
    ).subscribe({
      next:(data: any,) => {  
        this.vendorTypeInAdmin = data.result?.vendor_detail?.type;
        this.productStatus = this.vendorTypeInAdmin == 1  ?  ['Selling', 'Not Selling' ] : ['Mapped', 'Unmapped', 'Selling', 'Not Selling'] ;
     
        this.IdInVendorDetail = data.result.vendor_detail.type;
        this.userName = (data.result.vendor_detail.first_name + " " +  data.result.vendor_detail.last_name); 
        this.totalMappedCount = data.result?.mapped_product_total;
        this.suggested_effective_date = data?.result?.vendor_detail?.suggest_effective_date;
        if(loadMoreDetails){
          let newLoadedData = this.mapProductList(data.result.data.data);
          this.ProductItems =[...this.ProductItems , ...newLoadedData ];
        }
        else{
          this.ProductItems = this.mapProductList(data.result.data.data);
          // console.log(this.ProductItems)
        }
        this.loadingProduct = false;  
        this.isContentVisible = "visible"       
      },
      error:(err)=>{
        console.log(err);          
      }
    })
    :
    this.products.getVendorProductsVendorApp(this.payload).pipe(
      catchError(err => {
        throw(`${err}`)
      })
    ).subscribe(
      {
        next : (data: any) => {    
          this.vendorTypeInAdmin = data.result?.vendor_detail?.type;
          this.IdInVendorDetail = data.result?.vendor_detail?.type;
          this.totalMappedCount = data.result?.mapped_product_total;
          if(loadMoreDetails){
            let newLoadedData =this.mapProductList(data.result.data.data) ;
            this.ProductItems =[...this.ProductItems , ...newLoadedData ]
          }
          else{
            this.ProductItems = this.mapProductList(data.result.data.data);
          }
          this.loadingProduct = false;
          this.isContentVisible = "visible"
      
        },
        error : (err) =>{
          console.log(err)
        }
      }
    )
  }

  updateFilters(){
    
    // console.log(this.payload.order_by)
    this.loadingProduct =true;
  
    if (this.timeoutId) {
      this.loadingProduct =true;
      this.ProductItems =[];
      clearTimeout(this.timeoutId);
    }
    
    this.timeoutId = setTimeout(() => {
      this.ProductItems =[];
      this.loadingProduct =true;
      this.getProducts()
    }, 600);
  }

  loadMore(){
    this.dataPageNo++ ;
    this.getProducts(this.dataPageNo);
  }
 
  onScroll(){
    this.loadMore()
  }
  exportMasterProducts = false
  getMasterProductCSV() {
    this.exportMasterProducts = true
    const options = { 
      headers: ["product_id", "sku", "name", "erp_tsi_code", "price"]
    };
    this.products.exportMasterSheet(this.path).subscribe({
      next:(data: any) => {
        
        return new ngxCsv(data.result, 'master_product_list', options);
      },
      error:(error)=>{
        this._CommonService.errorHandler(error)
      }, 
      complete:()=>{
        this.exportMasterProducts = false

      }
    });
  }

  toggleAddProductModal(){
    this.addingSingleProductComponent.modaltoggle();
  }
  addPriceInMappedButNotSelling(productItem:VendorProductItem ){
    this.notSellingmappedProduct = productItem;  

    this.mappedNotsellingProductComponent.toggleAddPriceModal();
  }
  ngOnInit(): void {

    this.activatedRoute.parent?.paramMap.subscribe((data)=>{
      this.path = Number(data.get('id'));
      // console.log(this.path)
    });
    this.getProducts();
    this.setMappingUrl()
    // console.log(this.vendorType)
    // this.getMasterProductCSV();
   
    }
}
