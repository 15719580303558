import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PageNotFoundComponent } from './components/';
import { WebviewDirective } from './directives/';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { StoreModule } from '@ngrx/store';
import { orderDetailsReducer } from '../core/services/orders/order-details.reducer';
import {MatListModule} from '@angular/material/list';
import { DateAgoPipe } from '../core/pipes/date-ago.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [
    PageNotFoundComponent, 
    WebviewDirective, 
    HeaderComponent, 
    LoginComponent, ChangePasswordComponent,
  ],
  imports: [
    CommonModule, 
    TranslateModule, 
    FormsModule,
    MatInputModule,
    MatMenuModule,
    BrowserAnimationsModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatListModule,
    InfiniteScrollModule,
    StoreModule.forRoot({
      orderDetails: orderDetailsReducer,
    }),
    CountdownModule
  ],
  exports: [
    TranslateModule, 
    WebviewDirective, 
    FormsModule, 
    HeaderComponent,
    LoginComponent,   
    CountdownModule
  ],
  providers: [DateAgoPipe]
})
export class SharedModule {}
