import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../../../../environments/api_url';

@Injectable({
  providedIn: 'root'
})
export class MerchantsDetailsService {

  constructor(
    private http: HttpClient,
  ) { }

  vendorDetail(id: number) {
    let url
    if (!id) {
      url = api.URL + '/api/get-profile';
    } else {
      url = api.URL + '/api/vendor/detail';
    }
    const payload = {
      id: id
    };
    let dataPayload = this.http.post(
      url,
      payload
    )
    return dataPayload;
  }

  vendorRevenue(vendor_id: number) {
    const url = api.URL + '/api/vendor/specific-revenue';
    let payload = {
      vendor_id: vendor_id
    }
    let dataPayload = this.http.post(url, payload)
    return dataPayload
  }



  getMerchantStoreStatus() {
    const url = api.URL + '/api/store/time/status';
    let payload = {
      id:''
    }    
    let dataPayload = this.http.post(url, payload)
    return dataPayload
  }
}
