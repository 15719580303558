import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products.component';
import { ProductImportModalComponent } from './product-import-modal/product-import-modal.component';
import { ProductsMappingPopupsComponent } from './products-mapping-popups/products-mapping-popups.component';
import { MerchantProductMappingComponent } from './merchant-product-mapping/merchant-product-mapping.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { ProductDetailComponent } from './product-detail/product-detail.component';

import {MatExpansionModule} from '@angular/material/expansion';
import { register } from 'swiper/element/bundle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgOptimizedImage } from '@angular/common';
import { ProductSellingConfirmationComponent } from './product-selling-confirmation/product-selling-confirmation.component';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductListComponent } from './product-list/product-list.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AddingSingleProductComponent } from './adding-single-product/adding-single-product.component';
import { ProductCancelRequestComponent } from './product-cancel-request/product-cancel-request.component';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { NonerpReviewMappingComponent } from './nonerp-review-mapping/nonerp-review-mapping.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { CoreModule } from '../core/core.module';
import { MappedNotsellingProductComponent } from './mapped-notselling-product/mapped-notselling-product.component';


register();


@NgModule({
  declarations: [
    ProductsComponent,
    ProductImportModalComponent,
    ProductsMappingPopupsComponent,
    MerchantProductMappingComponent,
    ProductDetailComponent,
    ProductSellingConfirmationComponent,
    ProductListComponent,
    AddingSingleProductComponent,
    ProductCancelRequestComponent,
    NonerpReviewMappingComponent,
    MappedNotsellingProductComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ProductsRoutingModule,
    MatSelectModule,
    MatInputModule,
    RouterModule,
    MatRadioModule,
    MatTableModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    InfiniteScrollModule,
    NgOptimizedImage,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    
    NgxMaskDirective, 
    NgxMaskPipe,
    CoreModule
  ],
  providers:[
    provideEnvironmentNgxMask(),
  ],
  exports: 
  [ 
    ProductsComponent, 
    ProductImportModalComponent,
    ProductsMappingPopupsComponent,
    MerchantProductMappingComponent,
    ProductDetailComponent,
    ProductSellingConfirmationComponent,
    ProductListComponent,
    AddingSingleProductComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductsModule { }
