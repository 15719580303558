import { Component } from '@angular/core';


@Component({
  selector: 'app-products-main',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {

}
