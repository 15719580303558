import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProductsMappingPopupsComponent } from '../products-mapping-popups/products-mapping-popups.component';
import { VendorMappedItem } from '../../interfaces/vendor-data';
import { ProductsService } from '../../core/services/products/products.service';
import { ActivatedRoute } from '@angular/router';
import { ProductCancelRequestComponent } from '../product-cancel-request/product-cancel-request.component';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-merchant-product-mapping',
  templateUrl: './merchant-product-mapping.component.html',
  styleUrls: ['./merchant-product-mapping.component.scss'],
  animations:[
    trigger('slideDown', [
      state('hidden', style({ height: '120px', opacity: 1, overflow: 'hidden' })),
      state('visible', style({ height: '*', opacity: 1, overflow: 'hidden' })),
      transition('hidden => visible', animate('500ms ease-in')),
      transition('visible => hidden', animate('500ms ease-out')),
    ]),
  ]
})
export class MerchantProductMappingComponent implements OnInit {
  isContentVisible: string = 'hidden';
  path: number = 0;
  userName: string | null = '';
  IdInVendorDetail! : number;
  constructor(private products: ProductsService, private route: ActivatedRoute
    ) {
    if (localStorage.getItem('roleId') !== '1') {
      this.userName = localStorage.getItem('fullName')
    }
   }
  @ViewChild(ProductsMappingPopupsComponent) productMappingPopupsComponent!: ProductsMappingPopupsComponent;
  @ViewChild (ProductCancelRequestComponent) productCancelRequestComponent !: ProductCancelRequestComponent
  @Input() reviewMapUrl = 'mapURL';

  flagstatus!: number;
  popupProductName = {
    name: '',
    id: 0
  }
  payload : any = {
    keyword :'',
  }

  loadMorePageNo = 1;

   changePriceProductInfo = {
    product_id : 0,
    price: 0,
    sku : '',
    erp_tsi_code: ''
  }
  chgPricePrdName= '';
  producPrice = 0;
  OpenMappingPopup(flagstatus: number, ModalName: string, productId: number, price:number, erp_tsi_code: string, sku:string) {
    // console.log(this.path);
    
    this.flagstatus = flagstatus;
    
    this.popupProductName.name = ModalName,
    this.popupProductName.id = productId
    // console.log(this.popupProductName);
    this.changePriceProductInfo.price = price;
    this.changePriceProductInfo.sku = sku;
    this.changePriceProductInfo.erp_tsi_code = erp_tsi_code;
    this.producPrice =price; 
    
    this.changePriceProductInfo.product_id = productId;
    this.chgPricePrdName = ModalName;
    this.productMappingPopupsComponent.productMappingModalToggle();
  }
  timeoutId : any;
  tabelDataStatus = ''
  displayedColumns: string[] = ['product_name', 'erp_product_name', 'status', 'product_id'];
  vendorMappedItems: VendorMappedItem[] = [];
  mapVendorData(vendorMapData :any[]): VendorMappedItem[]{
    return vendorMapData.map((item: any) => {
      return {
        product_name: item?.name,
        sku: item?.sku,
        status: item?.vendor_products.length > 0 ? 1 : 0,
        product_id: item?.magento_id,
        product_img_url : item?.url,
        erp_product_name: item?.vendor_products[0]?.erp_vendor_products?.product_name,
        erp_product_url : item?.vendor_products[0]?.erp_vendor_products?.image,
        erp_tsi_code : item?.vendor_products[0]?.erp_tsi_code,
        price : item?.vendor_products[0]?.price,
        has_product_request : item?.has_vendor_product_request,
        has_mapped_request : item?.has_mapped_request,
        requested_price : item?.latest_vendor_product_request ?  item?.latest_vendor_product_request?.price : item?.latest_vendor_mapped_product_request?.price,
        update_requested_at : item?.latest_vendor_product_request ?  item?.latest_vendor_product_request?.created_at : item?.latest_vendor_mapped_product_request?.created_at,
      }
    })
  }
  cancelEvent !: number;
  cancelEventID ! : number;
  
  hasChildEvent = false;
  
  openRequesCancelPopup(cancelEvent : number, cancelEventID:number){
    this.cancelEvent = cancelEvent;
    this.cancelEventID = cancelEventID;
    this.productCancelRequestComponent.modalToggle();
  }

  eventFromChild(newItem: string) {
   
    // console.log(newItem)
    this.getList();
      
  }
  getUpdateListRequest( updateMsg : string){
    this.hasChildEvent = true;
    this.getList();
  }
 
  getEventFromPopup(requestType :string){
    this.hasChildEvent = true;
    if ( this.cancelEvent === 0){
      this.productCancelRequestComponent.cancelPriceRequest(this.cancelEventID)
    }
    else if (this.cancelEvent === 1){
      this.productCancelRequestComponent.cancelMappingRequest(this.cancelEventID)
    }
  }
  getList(loadMoreData ? : number) {
    loadMoreData ? this.payload.page = loadMoreData : (  this.loadMorePageNo = 1, this.payload.page= 1 );

    if (this.path &&  localStorage.getItem('roleId') ==='1') {
      this.products.productMappingAdmin(this.path, this.payload).subscribe((data: any) => {
        this.isContentVisible = 'visible';
        this.IdInVendorDetail = data.body.result.vendor_details.id;
        this.userName = (data.body.result.vendor_details.first_name + " " +  data.body.result.vendor_details.last_name); 
        // console.log(data.body.result.data)
        if (loadMoreData){
          let filteredData = this.mapVendorData(data.body.result.data.data)
          this.vendorMappedItems =  [...this.vendorMappedItems, ...filteredData]
          // console.log(this.vendorMappedItems)
        }
        else {
          this.vendorMappedItems =  this.mapVendorData(data.body.result.data.data);
        }
        this.vendorMappedItems.length > 0 ?  this.tabelDataStatus = '' : this.tabelDataStatus = 'No data found' ;
      })
    }
    else{
      this.products.productMappingVendorApp( this.payload).subscribe((data: any) => {
        this.isContentVisible = 'visible';
        this.IdInVendorDetail = data.body.result.vendor_details.id;
        if (loadMoreData){
          let filteredData = this.mapVendorData( data.body.result.data.data)
          this.vendorMappedItems =  [...this.vendorMappedItems, ...filteredData]
        }
        else {
          this.vendorMappedItems =  this.mapVendorData( data.body.result.data.data);
        }
        this.vendorMappedItems.length > 0 ?  this.tabelDataStatus = '' : this.tabelDataStatus = 'No data found' ;
      })
    }
  }
  updateFilters(){
    this.tabelDataStatus =''
    this.vendorMappedItems =[];
    if (this.timeoutId){
      clearTimeout(this.timeoutId);
    }
    
    this.timeoutId = setTimeout(()=>{
      this.getList()
    }, 500)
  
  }
  loadMore(){
    this.loadMorePageNo++ ;
    this.getList(this.loadMorePageNo)
  }
  onScroll(){
    this.loadMore();
  }
  ngOnInit(): void {
    this.route.parent?.paramMap.subscribe((data) => {
      this.path = Number(data.get('id'));  
    });
    this.getList()
  }
}
