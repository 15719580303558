import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NonErpProductList } from '../../interfaces/vendor-data';
import { ReviewMappingService } from '../../core/services/nonerp-review-mapping/review-mapping.service';
import { catchError, of } from 'rxjs';
@Component({
  selector: 'app-nonerp-review-mapping',
  templateUrl: './nonerp-review-mapping.component.html',
  styleUrls: ['./nonerp-review-mapping.component.scss']
})
export class NonerpReviewMappingComponent {
  @Output() newItemEvent = new EventEmitter <string>();
  @Input() vendorID!: number;
  constructor(
    private _ReviewMappingService : ReviewMappingService,
    public _snackBar: MatSnackBar,
  ){}
  productListModalStatus :boolean = false;
  errorMessage : string = "";
  selectedProduct! :NonErpProductList | null;
  loadMoreStatus = false;
  
  productListStatus = true;
  allPendingProductList!: NonErpProductList[];
  nonErpProductList : NonErpProductList[] =[];

  passEventToParent(value: string) {
    this.newItemEvent.emit(value);
  }
  searchProductText ="" ;
  modaltoggle(){
    this.errorMessage =""
    this.productListModalStatus ? (this.productListModalStatus = false) : (this.productListModalStatus = true);
    this.nonErpProductList = [];
    this.payload.keyword ="";
    this.selectedProduct = null; 
    this.searchProductText = "";
  }
 

  mapProductList(list :any[]): NonErpProductList[]{
    return list.map((item:any)=>{
      return({
        product_id: item?.magento_id,
        sku : item?.sku,
        erp_tsi_code :item?.erp_tsi_code,
        product_title: item?.name,
        product_url : item?.url,
        vendor_product_price : item?.vendor_products[0]?.price,
        vendor_product_status : item?.vendor_products?.length > 0 ? true: false,
      })
    })
  }
  payload = {
    vendor_id: this.vendorID,
    keyword: "",
    page: 1,
  }

  timeID : any;
  updateList(){
    this.productListStatus = false;
    this.nonErpProductList = [];
    if (this.timeID){
      clearTimeout(this.timeID)
    }
    this.timeID = setTimeout(()=>{
      this.productList()
    }, 500)
    
  }
  lastpage = 1;
  loadMorePage =1;
  productList(pageNumber ?: number){
    if (!this.loadMoreStatus){
      this.productListStatus = false;
      this.nonErpProductList = [];
    }

    (pageNumber && pageNumber <= this.lastpage) ? this.payload.page = pageNumber : (this.loadMorePage =1, this.payload.page = 1);

    if(pageNumber)
    {
      this._ReviewMappingService.nonErpProductsList(this.payload).pipe(
        catchError(err => {
          throw (err)
        })
        ).subscribe({
          next:(data :any)=>
          {
            if (this.payload.keyword.length > 0)
            {
              let NewList = this.mapProductList(data.result.data.data)
              this.nonErpProductList = [...this.nonErpProductList, ...NewList]
            } 
            else
            {
              this.nonErpProductList = []
            }
            this.lastpage =data.result?.data?.last_page;
            this.productListStatus = true;
            this.loadMoreStatus = false;
          
          },
          error: (err) =>{
            console.log(err);
          }
        })
    }
    else
    {  
      this._ReviewMappingService.nonErpProductsList(this.payload).pipe(
        catchError(err => {
          throw (err)
        })
      ).subscribe(
        {
         next: (data :any)=>
         {
            this.payload.keyword.length > 0 ? 
            (this.nonErpProductList = this.mapProductList(data.result.data.data))
            : (this.nonErpProductList = [] )
            this.lastpage =data.result?.data?.last_page;
            this.productListStatus = true;
         },
         error: (err) =>{
          console.log(err);
         }

      })
    }
   
  }

  radioChange(selectedProduct : any, list : any){
      if (selectedProduct && selectedProduct !=list ){
        selectedProduct.price =null;
        
      }    
  }
  
  loadmore(){
    this.loadMoreStatus = true;
    this.loadMorePage ++
    this.productList(this.loadMorePage)
  }

  addProductToList(){
    this.errorMessage ="";
    if (!this.selectedProduct){
      this.errorMessage = "A product needs to be selected, cannot submit empty form";
      return
    }
    else if (!this.selectedProduct.vendor_product_price){
      this.errorMessage = "Product price cannot be empty";
      return
    }
    if (this.selectedProduct.vendor_product_status){
      this._ReviewMappingService.nonErpPriceChange(this.selectedProduct, this.vendorID).pipe(
        catchError( err => {
          throw (err)
        })
      ).subscribe(
      {  
       next: (data : any)=>
        {
          if (data.status === "success") {
            this._snackBar.open(data.message, "", {
              duration: 2600,
              verticalPosition: 'top', horizontalPosition: 'end',
              panelClass: ['snackbar_green']
            })
            this.passEventToParent("success from child")
          }
          else
          {
            this._snackBar.open(data.message, "", {
              duration: 2600,
              verticalPosition: 'top', horizontalPosition: 'end',
              panelClass: ['snackbar_red']
            })
          }
         
        },
        error: (error) =>{
          console.log(error);
        }
      })
    }
    else
    {
      this._ReviewMappingService.launchSingleProduct(this.selectedProduct, this.vendorID).pipe(
        catchError (err => {
          throw (err)
        })
      ).subscribe({
        next : (data :any)=>
        {
          if (data.status === "success") {
            this._snackBar.open(data.message, "", {
              duration: 2000,
              verticalPosition: 'top', horizontalPosition: 'end',
              panelClass: ['snackbar_green']
            })
            this.passEventToParent("sucess from child")
          }
          else
          {
            this._snackBar.open(data.message, "", {
              duration: 2000,
              verticalPosition: 'top', horizontalPosition: 'end',
              panelClass: ['snackbar_red']
            })
          }
        },
        error: (error) =>{
          console.log(error);
        }
      })
    }
   
    this.modaltoggle();
    
  }
  ngOnInit(){
    this.payload.vendor_id = this.vendorID
    // console.log(this.payload.vendor_id)
  }
}
