import { Injectable } from '@angular/core';
import { api } from '../../../environments/api_url';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import 'moment-timezone'
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private updateVendorProfle = new Subject<any>();
  eventUpdateVendorProfle$ = this.updateVendorProfle.asObservable();

  constructor(
    private http: HttpClient,
    private _snackbar: MatSnackBar,

    ) { }

  sendEventProfileUpdate(event: any) {
    this.updateVendorProfle.next(event);
  }
  getStatusList() {
    const url = api.URL + '/api/vendor/status-list';
    return this.http.get(url);
  }
  vendorLocationList(payloadkeyword : string) {
    const url = api.URL + '/api/global/get-vendor-city';
    let payload ={
      keyword: payloadkeyword
    }
    return this.http.post(url, payload);
  }

  getCountries() {
    const url = api.URL + '/api/global/get-countries';
    return this.http.get(url);
  }

  getStates(id: any) {
    let payload = {
      country_id: id
    }
    const url = api.URL + `/api/global/get-states`;
    let dataPayload = this.http.post(
      url,
      payload,
      { observe: 'response' }
    )
    return dataPayload;
  }
  getCities(payload: any) {
    const url = api.URL + `/api/global/get-cities?state_id=${payload}`;
    let dataPayload = this.http.post(
      url,
      { observe: 'response' }
    )
    return dataPayload;
  }

  merchantsType(){
    let payload = {}
    const url = api.URL + `/api/erp/list`;
    return this.http.post(url,payload);
  }

  getTimeLeft(data: string) {
    const specificDate = new Date(data);
    const currentDate = new Date();
    const timeDiff = specificDate.getTime() - currentDate.getTime();
    const timeLeftInSeconds = Math.ceil(timeDiff / 1000) + 2 * 60;
    return timeLeftInSeconds
  }



  dataSorting(data: any[], sortingVar: any, type: string, key: string): any[] {
    let sortedData = [...data];
    let objectKey = key;

    if (type === 'number') {
      sortingVar[objectKey] ?
        sortedData.sort((a, b) => a[objectKey] - b[objectKey]) :
        sortedData.sort((a, b) => b[objectKey] - a[objectKey]);
      sortingVar[objectKey] = !sortingVar[objectKey];
    }

    if (type === 'date') {
      sortedData.sort((a, b) => {
        const dateA: any = new Date(a[objectKey]);
        const dateB: any = new Date(b[objectKey]);
        return sortingVar[objectKey] ? dateA - dateB : dateB - dateA;
      });
      sortingVar[objectKey] = !sortingVar[objectKey];
    }

    if (type === 'string') {
      sortingVar[objectKey] ?
        sortedData.sort((a, b) => a[objectKey].localeCompare(b[objectKey])) :
        sortedData.sort((a, b) => b[objectKey].localeCompare(a[objectKey]));
      sortingVar[objectKey] = !sortingVar[objectKey];
    }

    return sortedData;
  }

  formatDate(inputDate: string): string {
    console.log(inputDate);

    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const parts = inputDate.split('-');
    const day = parts[0].padStart(2, '0');
    const monthIndex = months.indexOf(parts[1]) + 1; // Adding 1 because months are 1-indexed
    const month = monthIndex.toString().padStart(2, '0');
    const year = parts[2];

    const formattedDate = `${day}-${months[monthIndex - 1]}-${year}`;
    console.log(formattedDate);

    // If you want to return in the format 'YYYY-MM-DD'
    return `${year}-${month}-${day}`;
  }


  errorHandler(error: unknown){
    this._snackbar.open("Oops! Something Went Wrong.", '', {
      duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
      panelClass: ['snackbar_red']
    })
    console.log("Somthing Went Wrong on server", error);
  }


  formatEmail(email:string){
    return email.toLowerCase()
  }

  formatPhone(phone:string){
    return phone?.split(' ')?.join('')
  }

  getUKTime(time:any) {
    return time ? moment.utc(time).tz('Europe/London').format('DD-MMM-YYYY h:mm A') : '-';
  }
}
