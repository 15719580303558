import { Injectable } from '@angular/core';
import { api } from '../../../../environments/api_url';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  getVendorProducts(vendorId: number, payload:any) {
    // const url = api.URL + '/api/get-vendor-products';
    const url = api.URL + '/api/vendor-product-list';
    const datapayload = {
      vendor_id: vendorId,
      keyword:payload.keyword,
      page:payload.page,
      order_by: payload.order_by,
      sort_by : payload.sort_by,
      status : payload.status,
      daterange_filter : payload.daterange_filter,
    }
    
    return this.http.post(url,datapayload)
  }

  getVendorProductsVendorApp( payload:any) {
    // const url = api.URL + '/api/get-vendor-products';
    const url = api.URL + '/api/vendor-product-list';
    const datapayload = {
      keyword:payload.keyword,
      page:payload.page,
      order_by: payload.order_by,
      sort_by : payload.sort_by,
      
      status : payload.status,
      daterange_filter : payload.daterange_filter,
    }
    return this.http.post(url,datapayload)
  }
 
 
  exportMasterSheet(id?:number) {
    const url = api.URL + '/api/export-all-master-product';
    id ? id + 1 : '' // adding plus one beacuse 1 is for admin
    let payload = {
      vendor_id:id
    }
    let data = this.http.post(url, payload)
    return data
  }


  importVendorProducts(formData: FormData): Observable<any> {

    // console.log(formData);
    // console.clear()
    // console.log(formData.get('file'));
    // console.log(formData.get('vendor_id'));
    const url = api.URL + '/api/import-vendor-products';
    return this.http.post(url, formData, { observe: 'response' });
  }


  productMappingAdmin(vendorId: number , getpayload : any) {
    const url = api.URL + '/api/review-mapping';
    const payload = {
      id: vendorId,
      page : getpayload.page,
      keyword : getpayload?.keyword
    }
    return this.http.post(
      url,
      payload,
      { observe: 'response' }
    )
  }
  productMappingVendorApp(getpayload : any) {
    const url = api.URL + '/api/review-mapping';
    const payload = {
      page : getpayload.page,
      keyword : getpayload.keyword
    }
    return this.http.post(
      url,
      payload,
      { observe: 'response' }
    )
  }
  
  postMappedProductRequest( selectedErpProduct: any  ){
    const url = api.URL + '/api/erp/mapped-products/create'
    const payload = {
      name : selectedErpProduct?.title,
      description: selectedErpProduct?.description,
      supplierPartNumber : selectedErpProduct?.supplierPartNumber,
      product_price : selectedErpProduct?.price,
      product_image: selectedErpProduct?.image,
      category_name : selectedErpProduct?.category_name,
      category_image : selectedErpProduct?.category_image,
      vat_percentage : selectedErpProduct?.vat_percentage,
      erp_product_id : selectedErpProduct?.id,
    }
    return this.http.post(url, payload)
  }

  mappingListProducts(vendorId: number, keyword: string) {
    // const url = api.URL + '/api/erp-product-list-to-map';
    const url = api.URL + '/api/erp/product-search';
    const payload = { id: vendorId, keyword: keyword }
    return this.http.post(url, payload)
  }

  requestProductMapMerchant(vendorId: number, price: number, erp_product_id: number, product_id: number) {
    const url = api.URL + '/api/request-to-map';
    const payload = {
      id: vendorId,
      erp_product_id: erp_product_id,
      product_id: product_id
    }
    return this.http.post(url, payload)
  }
  adminRequestProductMapMerchantwithPrice(vendorId: number, price: number, erp_product_id: number, product_id: number , effective_date : any){
    const url = api.URL + '/api/request-to-map';
    const payload = {
      id: vendorId,
      erp_product_id: erp_product_id,
      price: price,
      product_id: product_id,
      effective_date : effective_date
    }
    // console.log(effective_date);
    // console.log(payload);
    // return this.http
    //apcef1
    return this.http.post(url, payload)
  }
  requestProductMapMerchantwithPrice(vendorId: number, price: number, erp_product_id: number, product_id: number) {
    const url = api.URL + '/api/request-to-map';
    const payload = {
      id: vendorId,
      erp_product_id: erp_product_id,
      price: price,
      product_id: product_id,
      
    }
    //apcef1
    return this.http.post(url, payload)
  }
  


  changeProductPriceVendorApp( productInfo: any) {
    const url = api.URL + '/api/vendor-product-price-change';
    const payload = 
    { 
      vendor_product_change_price:[
        productInfo
      ],
      // for temperory testing purpose
      effective_date : new Date()
      // for temperory testing purpose 
    }
    return this.http.post(url, payload)
  }

  
  changeProductPriceAdminApp( vendorId: number , productInfo: any, effectiveDate : any) {
    const url = api.URL + '/api/vendor-product-price-change';
    const payload = 
    { 
      id: vendorId,
      vendor_product_change_price:[
        productInfo
      ],
      effective_date : effectiveDate
    }
    return this.http.post(url, payload)
  }
}
