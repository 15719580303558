import { Injectable } from '@angular/core';
import { api } from '../../../../environments/api_url';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SellingConfirmationService {

  constructor(
    private http : HttpClient
  ) { }

  sellingConfirmationRequestVendor(getPayload : any){
    const url = api.URL + '/api/single-product-request';
    let payload ={
      product_id : getPayload.product_id,
      price : getPayload.price,
      sku : getPayload.sku,
    }
   return this.http.post(url, payload)
  }
  sellingConfirmationRequestAdmin(getPayload : any, vendorID : number, effective_date : any){
    const url = api.URL + '/api/single-product-request';
    let payload ={
      product_id : getPayload.product_id,
      price : getPayload.price,
      sku : getPayload.sku,
      vendor_id : vendorID, 
      effective_date : effective_date
    }
   return this.http.post(url, payload)
  }
}
