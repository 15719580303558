import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyAccountService } from '../../../core/services/my-account/my-account.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  hide = true;
  passwords: string[] = [];
  showPassword: boolean[] = [];
  formData:any = {}
  changingPasswordloading = false
  passwordUpdateRequired = false;
  name:string | null = '';
  email:string | null = ''
  constructor(
    public _snackBar:MatSnackBar,
    private _MyAccountService:MyAccountService,
    private route:Router,
    
  ){
    if (localStorage.getItem('token')) {
      if (localStorage.getItem('passwordUpdateRequired') === '1'){
        this.passwordUpdateRequired = true
      } 
    } 
    this.name = localStorage.getItem('fullName');
    this.email = localStorage.getItem('email');

  }

  togglePasswordVisibility(index: number) {
    this.showPassword[index] = !this.showPassword[index];
  }

  changePassword(form: NgForm){
    
    if(this.formData.new_password !== this.formData.confirm_new_password){
      this._snackBar.open('New Password & Confirm New Password Must Be Same','',{
        duration:2000,verticalPosition: 'top', horizontalPosition: 'end',
        panelClass: ['snackbar_red']
      })
      return false
    }
    this.changingPasswordloading = true;
    this._MyAccountService.resetPassword(this.formData.old_password, this.formData.new_password, this.formData.confirm_new_password).subscribe((data:any)=>{
      this.changingPasswordloading = false;
      if(data.status == "success"){        
        form.resetForm()
        if(localStorage.getItem('passwordUpdateRequired')){
          localStorage.removeItem('passwordUpdateRequired');
          this.route.navigate(['login'])
        }
      }
      this._snackBar.open(data.message,'',{
        duration:3000,
        panelClass: ['snackbar_green'],
        verticalPosition: 'top', horizontalPosition: 'end',
      })
    })
  }
}
