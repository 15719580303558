import { Injectable } from '@angular/core';
import { api } from '../../../../environments/api_url';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ReviewMappingService {

  constructor(
    private http : HttpClient
  ) { }
  nonErpProductsList(getPayload : any){
    const url = api.URL + '/api/vendor-product-list';
    let payload ={
      vendor_id : getPayload.vendor_id,
      keyword: getPayload.keyword,
      page: getPayload.page,
    }
    return this.http.post(url,payload);
  }
  launchSingleProduct(getPayload : any, vendor_id : number){
    const url = api.URL + '/api/single-product-request';
    let payload ={
      price : getPayload.vendor_product_price,
      product_id: getPayload.product_id,
      sku : getPayload.sku,
      erp_tsi_code: getPayload.erp_tsi_code,
      vendor_id : vendor_id,
    }
    return this.http.post(url,payload);
  }
  nonErpPriceChange( productInfo: any, vendorId: number ){
    const url = api.URL + '/api/vendor-product-price-change';
    const payload = 
    { 
      id: vendorId,
      vendor_product_change_price:[
        {
          product_id: productInfo.product_id,
          price: productInfo.vendor_product_price,
          sku: productInfo.sku,
          erp_tsi_code:productInfo.erp_tsi_code,
        }    
      ]
    }
    return this.http.post(url, payload)
  }
}
