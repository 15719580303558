import { Component, ElementRef, HostListener, NgZone, ViewChild, effect } from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { ElectronService } from '../../../core/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SocketInjectorService } from '../../../core/services/socket/socket-injector.service';
import { NotificationService } from '../../../core/services/header-notification/notification.service';
import { OrdersService } from '../../../core/services/orders/orders.service';
import { Store } from '@ngrx/store';
import { OrderRequestDetail, OrderRequestDetailData } from '../../../interfaces/tabel-data';
import { setOrderDetails } from '../../../core/services/orders/order-details.actions';
import { DateAgoPipe } from '../../../core/pipes/date-ago.pipe';
import { catchError, of } from 'rxjs';
import { CommonService } from '../../../core/services/common.service';
import { MerchantsDetailsService } from '../../../core/services/merchant/merchants-details.service';
import { CountdownEvent } from 'ngx-countdown';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isSticky: boolean = false;
  isVendorDashboard?: boolean;
  orderRequestData: any = [];
  userName: string | null = '';
  hasNewNotification = 0
  showBanner = true
  headerStrip = ''

  // #scrollNotification
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const elementOffset = this._el.nativeElement.getBoundingClientRect().top;

    if (elementOffset <= -120) {
      this.isSticky = true;
    }
    else if (elementOffset == 0) {
      this.isSticky = false;
    }

  }

  loggedIn?: boolean;
  installApp: boolean = true;
  notficationList?: any[];
  isOnline: boolean = true;
  constructor
    (
      private auth: AuthService,
      private _el: ElementRef,
      private electron: ElectronService,
      public _snackBar: MatSnackBar,
      private route: Router,
      private socketService: SocketInjectorService,
      private notificationListService: NotificationService,
      private orderServiceVendor: OrdersService,
      private zone: NgZone,
      private _dataAgo: DateAgoPipe,
      private _CommonService: CommonService,
      private _MerchantsDetailsService: MerchantsDetailsService,

    ) {



    // gettting internet connectiivity 
    if (navigator.onLine) {
      this.isOnline = true;
    } else {
      this.isOnline = false;
    }

    effect(() => {
      if (this.auth.userLoginStatus()) {
        this.auth.isUserVendor() ? this.isVendorDashboard = true : this.isVendorDashboard = false;
        this.loggedIn = true
        if (localStorage.getItem('roleId') !== '1') {
          this.userName = localStorage.getItem('fullName')
          this._CommonService.eventUpdateVendorProfle$.subscribe((data) => {
            // console.log(data);
            this.getStoreTime()
          })

          this.getStoreTime()

        } else if (localStorage.getItem('roleId') === '1') {
          this.userName = 'Admin'
        } else {
          this.userName = 'User'
        }

        this.getNotifications();


      } else {
        this.loggedIn = false
        this.isVendorDashboard = false;
      }

    });

  }

  @HostListener('window:online', ['$event'])
  onOnlineEvent(event: Event) {
    this.isOnline = true;
  }

  @HostListener('window:offline', ['$event'])
  onOfflineEvent(event: Event) {
    this.isOnline = false;
  }

  showNotification = true
  initializeSocket() {
    this.socketService.injectSocket().subscribe((data: any) => {
      // console.log("socket data", data);

      this.handleSocketData(data);
    });
  }

  handleSocketData(data: any) {
    // same cases add in notification list service to handle notification clicks
    if (!data) {
      return;
    }

    let socketData = data;
    // console.log(socketData, "sddsa")
    const vendorId = Number(localStorage.getItem('hashid'));
    this.orderServiceVendor.sendOrderUpdateStatus(socketData);
    const vendorType = socketData.data.vendor_id === vendorId && localStorage.getItem('roleId') === '2'
    switch (socketData.type) {
      case 1:
        // console.log(socketData);

        const notificationBody = `Order Id: #${socketData.data.order_number}`;
        vendorType ? this.electron.sendNotes('One New Order Request Has Been Received', notificationBody, socketData) : ''
        // this.handleOrderProductRequest(socketData);
        this.orderServiceVendor.sendEventOfNewOrderStatus(socketData)
        this.getNotifications()
        break;
      case 2:
        // Handle ORDER_TIMELINE_UPDATE if needed          
        this.orderServiceVendor.sendEventOfOrderStatus(socketData)
        vendorType ? this.electron.sendNotes('Order Status', "Order request has been updated!", socketData) : ''
        this.getNotifications()
        break;
      case 3:
        // ORDER ASSIGNED
        this.getNotifications()
        vendorType ? this.electron.sendNotes('New Order Assinged', "New Order has Been Assinged!", socketData) : ''
        break;
      case 4:
        // ORDER Closing Time Status
        // console.log(socketData);
        this.getStoreTime()
        // this.electron.sendNotes('Store Closing Time', "New Order Has Been Assinged!", socketData)
        break;
      default:
        console.log('Unknown event type', socketData.type);
    }
  }
  notificationPage = 1
  isMoreNotification = false
  onScroll() {
    if (this.isMoreNotification) {
      this.notificationPage++
      this.getNotifications(this.notificationPage)
      // this.isMoreNotification = false
    }

  }



  getNotifications(isLoadedMore?: number) {
    let payload = {
      page: 1,
      // permater for ordering\
      sort_by: 'created_at',
      order_by: 'desc'
    }
    if (isLoadedMore) {
      payload.page = isLoadedMore
    }
    this.notificationListService.getNotificationList(payload)
      .pipe(
        catchError(err => {
          throw err
        })
      ).subscribe(
        {
          next: (data: any) => {
            let notificationArray = data.result.notification_data.data
            let notificationUnRead = data.result.unread;
            this.hasNewNotification = notificationUnRead
            try {
              if (notificationArray.length != '') {
                this.zone.run(() => {
                  if (notificationArray === null) {
                    this.isMoreNotification = false
                  } else {
                    this.isMoreNotification = true

                  }
                  if (isLoadedMore) {
                    notificationArray.forEach((item: any) => {
                      item.created_at = this._dataAgo.transform(item.created_at)
                      item.isExpired = this._dataAgo.transform(item.created_at)
                    })
                    if (this.notficationList) {
                      // let notificationContainer = document.getElementById('scrollNotificationWrapper')
                      // notificationContainer?.scroll(0, -1)
                      // notificationContainer?.scroll(0, 1)
                      this.notficationList = [...this.notficationList, ...notificationArray]
                    }

                    console.log(notificationArray);
                    console.log(this.notficationList);
                  } else {
                    this.notficationList = notificationArray
                    this.notficationList?.forEach((item) => {
                      item.created_at = this._dataAgo.transform(item.created_at)
                      item.isExpired = this._CommonService.getTimeLeft(item.created_at)
                    })
                  }
                  // console.log(this.notficationList);

                })
              } else {
                if (!isLoadedMore) {
                  this.hasNewNotification = 0
                  this.notficationList = [{
                    title: 'No New Notifications',
                    description: 'You will find important information here.'
                  }]
                }
              }
            } catch (error) {
              this._snackBar.open("Oops! Something Went Wrong.", '', {
                duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
                panelClass: ['snackbar_red']
              })
              this.notficationList = [{
                title: 'Oops! something went wrong!',
                description: 'You will find important information here.'
              }]
              console.log(error);
            }
          },

          error: (error) => {
            // this._snackBar.open("Oops! Something Went Wrong.", '', {
            //   duration: 2000,
            //   panelClass: ['snackbar_red']
            // })
            console.log(error);
          }
        }
      )
  }

  setAllNotificationRead() {
    this.notificationListService.setAllReadNotification().subscribe({
      next: (value: any) => {
        if (value.status_code == 200) {
          this.hasNewNotification = 0
        }

      },
      error: (err) => {
        console.log(err);

      },
    })
  }


  clickNotification(notificationData: any) {
    let orderObject = {
      type: notificationData.type,
      data: JSON.parse(notificationData.data),
      notification_id: notificationData.id
    }
    this.notificationListService.setReadNotification(orderObject.notification_id).subscribe(
      {
        next: (response: any) => {
          try {
            if (this.notficationList) {
              let index = this.notficationList?.findIndex((item: any) => item.id == orderObject.notification_id)
              this.notficationList[index].is_read = 1
              if (this.notficationList[index].is_read) {
                if (response.status === 200) {

                }
              }
            }
          } catch (error) {
            console.log("Somthing Went Wrong", error);
          }
        },
        error: (error) => {
          console.log("Somthing Went Wrong on server", error);
        }
      }
    )



    this.notificationListService.notificationEventHandle(orderObject)

  }

  checkQuantityofLineItems(data: any) {
    let quantity = 0
    data.forEach((element: { orderProductQty: number; }) => quantity += element.orderProductQty);
    return quantity
  }
  checkReceivedTime(data: any) {
    let time = null
    data.forEach((element: { created_at: number; }) => time = element.created_at);
    return time
  }

  checkPriceofLineItems(data: any) {
    let amount = 0
    data.forEach((element: { orderProductQty: number; orderProductPrice: number; }) => amount += element.orderProductQty * element.orderProductPrice);
    return amount
  }




  ngOnInit(): void {
    if (localStorage.getItem('token') && localStorage.getItem('userData')) {
      // this.loggedIn = true;
      // this.getNotifications()
    }
    this.initializeSocket();

    // this.checkStoreStatus()
  }
  logout() {

    this.auth.userLogout().subscribe({
      next: (data: any) => {
        // if (data.status == "success") {
        localStorage.clear();
        this.route.navigate(['login'])

        this._snackBar.open(data.message, '', {
          duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_green']
        })
      },
      error: (error) => {
        localStorage.clear();
        console.log("logout error", error);
      }
    })

    setTimeout(() => {
      localStorage.clear();
      this.route.navigate(['login'])
    }, 1000);
  }


  showNotificationBar = false;


  // Declare timerStrip at the class level
  timerStrip: any;
  showTimer = false;
  headerNotificationTimer = 0
  getStoreTime() {
    this.headerStrip = 'Not Receiving Orders Currently';
    this._MerchantsDetailsService.getMerchantStoreStatus().subscribe({
      next: (data: any) => {
        let apiResponse = data.result
        try {
          // console.log(data.result);
          this.showNotificationBar = !apiResponse.status
          if (apiResponse.close_within <= 15 && apiResponse.close_within > 0) {
            this.showTimer = true
            this.headerStrip = 'Your Store Is about to close in ';
            this.headerNotificationTimer = Number(apiResponse.close_within * 60)
          }
        } catch (error) {
          console.log("Vendor Store Time Data", error);
        }
      },
      error: (error) => {
        console.log("Vendor Store Time API", error);
      }
    });
  }

  handleNotificationStripEvent(e: CountdownEvent) {
    if (e.action === 'done') {
      this.headerStrip = 'Not Receiving Orders Currently';
      this.showTimer = false
      this.showNotificationBar = true
    }
  }






}
