import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../../../../environments/api_url';
@Injectable({
  providedIn: 'root'
})
export class CancelRequestService {

  constructor(
    private http : HttpClient
  ) { }

  cancelRequestAdmin( magento_id:number, vendorId : number){
    const url = api.URL + '/api/cancel-product-request';
    let payload ={
      product_id: magento_id,
      vendor_id : vendorId,
    }
    return (this.http.post(url, payload))
  }
  cancelRequestVendor( magento_id:number){
    const url = api.URL + '/api/cancel-product-request';
    let payload ={
      product_id: magento_id,
    }
    return (this.http.post(url, payload))
  }
  cancelMappingRequestAdmin( magento_id:number, vendorId : number){
    const url = api.URL + '/api/product/cancel-erp-product-request';
    let payload ={
      product_id: magento_id,
      vendor_id : vendorId,
    }
    return (this.http.post(url, payload))
  }
  cancelMappingRequestVendor( magento_id:number){
    const url = api.URL + '/api/product/cancel-erp-product-request';
    let payload ={
      product_id: magento_id,
    }
    return (this.http.post(url, payload))
  }
 
}
