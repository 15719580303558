import { Component, Output, EventEmitter, Input } from '@angular/core';
import { catchError, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CancelRequestService } from '../../core/services/cancel-vendor-request/cancel-request.service';
@Component({
  selector: 'app-product-cancel-request',
  templateUrl: './product-cancel-request.component.html',
  styleUrls: ['./product-cancel-request.component.scss']
})
export class ProductCancelRequestComponent {
  @Output() requestEvent = new EventEmitter<string>();
  @Output() updateListRequest = new EventEmitter<string>();
  @Input()  path ! : number;

  constructor(
    private _cancelRequestService : CancelRequestService,
    private _snackBar : MatSnackBar,
  ){

  }
  
  passEventToParent(value: string) {
    this.requestEvent.emit(value);
  }
  updateRequest( value :string){
    this.updateListRequest.emit(value)
  }
  cancelMappingRequest(product_id: number){
    this.modalToggle();
    this.path !== 0 &&  localStorage.getItem('roleId') ==='1' ? 
    this._cancelRequestService.cancelMappingRequestAdmin(product_id, this.path).pipe(
      catchError(err => {
        throw (err)
      })
    ).subscribe({
      next : (data : any) =>{
        
        // console.log(data);
        // this.hasChildEvent = true;
        this.updateRequest("update list")
        if (data.status === "success") {
          this._snackBar.open(data.message, "", {
            duration: 3200, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_green']
          })
          // console.log(data, "find out")
          
        } 
        else{
          this._snackBar.open(data.message, "", {
            duration: 3200, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_red']
          })
        }
      },
      error: (err) =>{
        console.log(err);
      }

    })
    :
    this._cancelRequestService.cancelMappingRequestVendor(product_id).pipe(
      catchError(err => {
        throw (err)
      })
    ).subscribe({
      next : (data : any) =>{
        // console.log(data);
        
        // this.hasChildEvent = true;
        this.updateRequest("update list")
        if (data.status === "success") {
          this._snackBar.open(data.message, "", {
            duration: 3200, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_green']
          })
          // console.log(data, "find out")
          
        } 
        else{
          this._snackBar.open(data.message, "", {
            duration: 3200, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_red']
          })
        }
      },
      error: (error) =>{
        console.log(error)
      }
    })
  }
  cancelPriceRequest(product_id: number){
    this.modalToggle();
    this.path!== 0 &&  localStorage.getItem('roleId') ==='1' ? 
    this._cancelRequestService.cancelRequestAdmin(product_id, this.path).pipe(
      catchError(err => {
        throw (err)
      })
    ).subscribe({
      next : (data : any) =>{
        // console.log(data);
    
        // this.hasChildEvent = true;
        this.updateRequest("update list")
        if (data.status === "success") {
          this._snackBar.open(data.message, "", {
            duration: 3200, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_green']
          })
          // console.log(data, "find out")
          
        } 
        else{
          this._snackBar.open(data.message, "", {
            duration: 3200, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_red']
          })
        }
      },
      error: (error) =>{
        console.log(error);
      }

    })
    :
    this._cancelRequestService.cancelRequestVendor(product_id).pipe(
      catchError(err => {
        throw (err)
      })
    ).subscribe({
      next : (data : any) =>{
        // console.log(data);
        
        // this.hasChildEvent = true;
        this.updateRequest("update list")
        if (data.status === "success") {
          this._snackBar.open(data.message, "", {
            duration: 3200,verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_green']
          })
          // console.log(data, "find out")
          
        } 
        else{
          this._snackBar.open(data.message, "", {
            duration: 3200, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_red']
          })
        }
      },
      error: (error) =>{
        console.log(error)
      }
    })
  }
  modalStatus = false;
  modalToggle(){
    this.modalStatus? (this.modalStatus = false) :(this.modalStatus = true )
  }
  triggerCancelRequest(){
    this.passEventToParent("request canceld")
  }
}
