// order-details.actions.ts

import { createAction, props } from '@ngrx/store';
import { OrderRequestDetail, OrderRequestDetailData } from '../../../interfaces/tabel-data';

export const setOrderDetails = createAction(
  '[Order Details] Set Order Details',
  props<{ orderRequestData: OrderRequestDetail[] }>()
);

