import { Component, Input, SimpleChanges, OnInit, ViewChild, Output,  EventEmitter } from '@angular/core';
import { SelectedErpProduct } from '../../interfaces/vendor-data';
import { ProductsService } from '../../core/services/products/products.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-products-mapping-popups',
  templateUrl: './products-mapping-popups.component.html',
  styleUrls: ['./products-mapping-popups.component.scss']
})
export class ProductsMappingPopupsComponent implements OnInit {

  @Input() popupFlag!: number;
  @Input() ModalName!: string;
  @Input() productId!: number;
  @Input() vendorID!: number;
  @Input () changePriceProductInfo! :any;
  @Input () changePriceProductName !: string;
  @Input() ExistingProducPrice ! :number;
  @Input() IdInVendorDetail ! : number;
  @Input() Suggested_effective_date ! : Date;
  @Output() newItemEvent = new EventEmitter<string>();
  today = new Date();

  passEventToParent(value: string) {
    this.newItemEvent.emit(value);
  }
  constructor(private ProductsService: ProductsService, private route: ActivatedRoute,public _snackBar: MatSnackBar,) { }
  
  ModalPropupStatus = "Start searching products"
  dateValid = true;
  formatValidate($event: any) {
    // console.log($event);
    if ( $event){
      // console.log($event?.value);
      
      this.dateValid = true
    }
    else{
      this.dateValid = false;
    }
  }
  modalStatus: boolean = false;
  switch_expression: number | undefined;
  selectedErpProduct!: SelectedErpProduct;
  selectedErpProductforNonErp!: SelectedErpProduct | null;
  ErpProducWithtNewPrice!: number | null;
  productListStatus = true;
  erpproductsData: SelectedErpProduct[] = [];
  adminAppStatus = localStorage.getItem('roleId') ==='1';
  effectiveDate !: Date | null ;
  mapeffectiveDate!: Date | null ;
  errorMessage : string ='';
  requestToMapEffectiveDate : boolean = false;
  adminselectedErpProduct !: (SelectedErpProduct  | null);
  highPriceStatus =false;
  noEffectiveDatemessage = 'Please select effective date';
  
  mapErpProuctWithPrice(selectedProduct?: SelectedErpProduct| null) {
    
    if (!selectedProduct){
      this.errorMessage = "A product needs to be selected, cannot submit empty form";
      return
    }
    else if (!selectedProduct.price){
      this.errorMessage = "Product price cannot be empty";
      return
    }
 
    // console.log(this.vendorID, selectedProduct?.price);
    this.errorMessage ='';
      
    if (selectedProduct){
    localStorage.getItem('roleId') ==='1' ? this.vendorID = this.vendorID : this.vendorID = Number(localStorage.getItem('hashid'))
    localStorage.getItem('roleId') ==='1' ? this.RequestErpToMapWithDate(selectedProduct)   :  this.requestErpToMapWithPrice(selectedProduct)
    }
    selectedProduct = null;
  }
  
  RequestErpToMapWithDate(selectedProduct : SelectedErpProduct){
    this.adminselectedErpProduct = null;
    if (selectedProduct){
      this.RequestErpToMapWithDateToggle()
      this.adminselectedErpProduct = selectedProduct;
      this.modalStatus = false;
    }
   
  }

  RequestErpToMapWithDateToggle(){
    this.requestToMapEffectiveDate = !this.requestToMapEffectiveDate;
  }
 
  submitRequestErpToMapWithDate(){
    if (!this.mapeffectiveDate){
      this._snackBar.open(this.noEffectiveDatemessage, "", {
        duration: 2600, verticalPosition: 'top', horizontalPosition: 'end',
        panelClass: ['snackbar_red']
      })
        return 
    }
    if(this.adminselectedErpProduct){
      this.requestErpToMapWithPrice(this.adminselectedErpProduct);
    }
    this.RequestErpToMapWithDateToggle()
    
  }

  requestErpToMapWithPrice(selectedProduct: SelectedErpProduct){
    if (!selectedProduct){
      this.errorMessage = "A product needs to be selected, cannot submit empty form";
      return
    }
    if (this.mapeffectiveDate){
      this.mapeffectiveDate = this.effectiveDateSelection(this.mapeffectiveDate)
    }
    localStorage.getItem('roleId') ==='1' ? 
    this.ProductsService.adminRequestProductMapMerchantwithPrice(this.vendorID, selectedProduct?.price, selectedProduct?.id, this.productId, this.mapeffectiveDate).subscribe((data: any) => {
      // console.log(data);
      if (data.status == 'success') {
        this.passEventToParent("sucess from child")
        this.erpproductsData = [];
        this.modalStatus = false;
        this._snackBar.open(data.message, "", {
          duration: 2600, verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_green']
        })
        this.createMappingRequest(selectedProduct)
      } 
      else {
        this.modalStatus = false;
        this._snackBar.open(data.message, "", {
          duration: 2000,verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_red']
        })
       
      }
      this.mapeffectiveDate = null;
    })
    :

    this.ProductsService.requestProductMapMerchantwithPrice(this.vendorID, selectedProduct?.price, selectedProduct?.id, this.productId).subscribe((data: any) => {
      // console.log(data);
      if (data.status == 'success') {
        this.passEventToParent("sucess from child")
        this.erpproductsData = [];
        this.modalStatus = false;
        this._snackBar.open(data.message, "", {
          duration: 2600, verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_green']
        })
        this.createMappingRequest(selectedProduct)
      } 
      else {
        this.modalStatus = false;
        this._snackBar.open(data.message, "", {
          duration: 2000,verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_red']
        })
       
      }
    })
  }

  createMappingRequest(selectedErpProduct : SelectedErpProduct){
    this.ProductsService.postMappedProductRequest(selectedErpProduct).subscribe((data:any)=>{
      // console.log(data?.message)
  })
    
  }

  radioChanges(selectedProduct : any, list : any){
    if (selectedProduct && selectedProduct !=list ){
      selectedProduct.price =null;
    }
    
  }
  mapErpProduct(selectedProduct: SelectedErpProduct){
    if (!selectedProduct){
      this.errorMessage = "A product needs to be selected, cannot submit empty form";
      return
    }
 
    // console.log(this.vendorID, selectedProduct?.price);
    this.errorMessage ='';
   localStorage.getItem('roleId') ==='1' ? this.vendorID = this.vendorID : this.vendorID = Number(localStorage.getItem('hashid'))
    this.ProductsService.requestProductMapMerchant(this.vendorID, selectedProduct?.price, selectedProduct?.id, this.productId).subscribe((data: any) => {
      // console.log(data);
      if (data.status == 'success') {
        this.passEventToParent("sucess from child")
        this.erpproductsData = [];
        this.modalStatus = false;
        this._snackBar.open(data.message, "", {
          duration: 2600, verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_green']
        })
       
      } 
      else {
        this.modalStatus = false;
        this._snackBar.open(data.message, "", {
          duration: 2000,verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_red']
        })
       
      }
    })
  }

  highPriceConfirmationPopupToggle(){
    this.highPriceStatus ? (this.highPriceStatus = false ): (this.highPriceStatus = true )
    this.ErpProducWithtNewPrice= null;
  }
  effectiveDateSelection(event: any): any {
    const date = new Date(event);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; 
    const day = date.getDate(); 

     return `${year}-${month}-${day}`;
  }
  
  priceChangePostRequest(){
      if (this.effectiveDate){
        this.effectiveDate = this.effectiveDateSelection(this.effectiveDate)
      }
    localStorage.getItem('roleId') ==='1' ? this.vendorID = this.vendorID : this.vendorID = Number(localStorage.getItem('hashid'));

    (localStorage.getItem('roleId') ==='2') ? 
    this.ProductsService.changeProductPriceVendorApp(this.changePriceProductInfo).subscribe((data:any)=>{
      // console.log(data)
      if (data.status === "success") {
        this._snackBar.open(data.message, "", {
          duration: 2000,verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_green']
        })
        this.passEventToParent("sucess from child")
      } else{
        this._snackBar.open(data.message, "", {
          duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_red']
        })
      }
      
    })
    :
    this.ProductsService.changeProductPriceAdminApp(this.vendorID, this.changePriceProductInfo, this.effectiveDate ).subscribe((data:any)=>{
      // console.log(data.message)
      if (data.status === "success") {
        this._snackBar.open(data.message, "", {
          duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_green']
        })
        this.passEventToParent("sucess from child")
      } else{
        this._snackBar.open(data.message, "", {
          duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
          panelClass: ['snackbar_red']
        })
      }
  
      this.effectiveDate = null;
    });
    
    
  }
  searchProduct ! : string;
  submitHighChangedPrice(){
      this.priceChangePostRequest()
      // console.log(this.changePriceProductInfo.price)
      this.highPriceConfirmationPopupToggle()
  }
  productMappingModalToggle(): void {    
    this.modalStatus = !this.modalStatus;
    this.ErpProducWithtNewPrice= null;
    this.erpproductsData =[];
    this.searchProduct ='';
    this.errorMessage ='';
    this.selectedErpProductforNonErp= null;
    
  }
  changeProductPrice(selectedPrice: number| null ) {
    if(!selectedPrice) return
    this.changePriceProductInfo.price = Number(selectedPrice)
    let excessiveAmount =  Number( 1.05 * Number(this.ExistingProducPrice) );
    if (excessiveAmount >= this.changePriceProductInfo.price){
         this.priceChangePostRequest();
         this.productMappingModalToggle();      
    }
    else
    { 
      this.productMappingModalToggle()
      this.highPriceConfirmationPopupToggle()
      // console.log( "high amount",excessiveAmount)
    }
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['popupFlag']) {
      this.switch_expression = changes['popupFlag'].currentValue;
    }
  }
  radioChange(selectedProduct : any, list : any){
    if (selectedProduct && selectedProduct !=list ){
      selectedProduct.price =null;
    }
    
  }
  timeID : any;
  erpProductListToMap( keyword : string){
    this.productListStatus = false;
    this.erpproductsData =[];
    if (keyword.length > 0 ){
      // console.log("sd", this.route.parent?.snapshot.paramMap.get('id') )
      this.ProductsService.mappingListProducts(Number(this.vendorID), keyword).subscribe((data: any) => {
        this.erpproductsData = data.result.map((item: any) => {
          return {
            image: item.product_images[0]?.url,
            title: item.name,
            id: item.id,
            price: null,
            selectedState:'false',
            description: item.description,
            supplierPartNumber : item.supplierPartNumber,
            erp_product_price : item.erp_product_price,
            category_name : item.category_name,
            category_image : item.category_image.url,
            vat_percentage : item.vat_percentage
          }
        })
        
        !this.erpproductsData.length ? this.ModalPropupStatus = "Data Not Found" : this.ModalPropupStatus = "Start searching products";
        this.productListStatus = true;
      })
      
    }
    else {
      this.erpproductsData =[];
      this.productListStatus = true;
      this.ModalPropupStatus= "Start searching products"
    }
  }
  getEnterEvent(event :any){
    if ( !this.searchProduct && this.erpproductsData.length == 0 ){
      return
    }
    else if (event.keyCode == 13 ){
      this.getProductsData(this.searchProduct)
    }
    else{
      return
    }
  }

  getProductsData(keyword: string) {
    if (!keyword && this.erpproductsData.length == 0 ){
      return
    }
   
      this.productListStatus = false;
      this.erpproductsData =[];
     this.erpProductListToMap(keyword)
 
  }
  clearDate(){
    this.mapeffectiveDate = null;
    this.effectiveDate = null;
  }
  ngOnInit(): void {
    
  }
}