import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ElectronService } from '../core/services';
import { ipcRenderer } from 'electron';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent {
  ipcRenderer!: typeof ipcRenderer;
  constructor(
    private electronService: ElectronService,
    private ngZone: NgZone,
    private router: Router
  ) {
    if (this.electronService.isElectron) {
      // console.log("response");
      this.ipcRenderer = (window as any).require('electron').ipcRenderer;
      this.ipcRenderer.on('notification-clicked', (event: any, response: any) => {
        // console.log(response);
        this.manageOrderData(response)
        
      });
    }
  }


  manageOrderData(response:any){
    this.ngZone.run(() => {

      let type = response.orderData.type
      let orderID = response.orderData.data.id
      let brodcast_order_id = response.orderData.data.order_id
      // console.log(response);

      if (type === 1) {

        this.router.navigate([`merchants/dashboard/order-request-detail/${brodcast_order_id}`]);
      } else if (type === 2) {


        this.router.navigate([`merchants/orders/details/${orderID}`])
      } else if (type === 3) {
        this.router.navigate([`merchants/orders/details/${orderID}`])
      }

    });
  }
}
