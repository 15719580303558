import { Component, ViewChild } from '@angular/core';
import { ProductDetailService } from '../../core/services/product-detail/product-detail.service';
import { ActivatedRoute } from '@angular/router';
import { ProductSellingConfirmationComponent } from '../product-selling-confirmation/product-selling-confirmation.component';
import { ProductsMappingPopupsComponent } from '../products-mapping-popups/products-mapping-popups.component';
import { CancelRequestService } from '../../core/services/cancel-vendor-request/cancel-request.service';
import { catchError, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductCancelRequestComponent } from '../product-cancel-request/product-cancel-request.component';
@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent {
  @ViewChild (ProductSellingConfirmationComponent) productSellingConfirmationComponent !:ProductSellingConfirmationComponent 
  @ViewChild (ProductsMappingPopupsComponent) productMappingPopupsComponent! :ProductsMappingPopupsComponent;
  @ViewChild(ProductCancelRequestComponent) productCancelRequestComponent !: ProductCancelRequestComponent
  IdInVendorDetail!: number;
  sellingProductInfo ={
    product_id : 0,
    sku : '',
    product_name :''
  }
  constructor(
    private _productDetailService: ProductDetailService,
    private activatedRoute : ActivatedRoute,
    private _cancelRequestService : CancelRequestService,
    private _snackBar : MatSnackBar,
  ){}
  vendorType = localStorage.getItem('vendorType') === '1' ? 'nonerp' : ( localStorage.getItem('vendorType') === '2' ? 'erp': "admin" )
  
  openSellingModal(product_id: number, sku: string , product_name : string){
    this.productSellingConfirmationComponent.modalToggle()
    this.sellingProductInfo.product_id = product_id;
    this.sellingProductInfo.sku = sku;
    this.sellingProductInfo.product_name =  product_name;
  }
  loadingData =false;
  panelOpenState = false;
  slides:string[]= [];
  suggested_effective_date ! :Date;
  path! : number; 
  productDetailJson :any ={};
 
  VendorAppStatus = localStorage.getItem('roleId') ==='2' 
  changePriceProductInfo = {
    product_id : 0,
    price: 0,
    sku : '',
    erp_tsi_code: ''
  }
  pathnew! : number;
  vendorTypeInAdmin! : number ;
  chgPricePrdName= '';
  producPrice = 0;
  flagstatus!: number;
  cancelEvent !: number;
  cancelEventID ! : number;
  hasChildEvent = false;
  requestedPrice ! : number;
  requestedUpdateAt ! : Date;

  openRequesCancelPopup(cancelEvent : number,cancelEventID :number ){
    this.cancelEvent = cancelEvent;
    this.cancelEventID = cancelEventID;
    this.productCancelRequestComponent.modalToggle();
  }
  getUpdateListRequest( updateMsg : string){
    this.hasChildEvent = true;
    this.productDetail(this.path);  
  }
  getEventFromPopup(requestType :string){
    
    if ( this.cancelEvent === 0){
      this.productCancelRequestComponent.cancelPriceRequest(this.cancelEventID)
    }
    else if (this.cancelEvent === 1){
      this.productCancelRequestComponent.cancelMappingRequest(this.cancelEventID)
    }
  }
  OpenMappingPopup(flagstatus: number, productId:number, price:number, sku:string, erp_tsi_code:string, productName:string) {
    this.productMappingPopupsComponent.productMappingModalToggle();
    this.flagstatus = flagstatus;
    this.changePriceProductInfo.product_id = productId;
    this.changePriceProductInfo.price = price;
    this.changePriceProductInfo.sku = sku;
    this.changePriceProductInfo.erp_tsi_code = erp_tsi_code;
    this.chgPricePrdName = productName;
    this.producPrice = price;
  }
  
  eventFromChild(newItem: string) {
    this.hasChildEvent = true;
    // console.log(newItem)
    this.productDetail(this.path); 
      
  }
  
 
  hasRequest!: number;
  hasmappedRequest ! : number; 
  productDetail(id :number){
    (localStorage.getItem('roleId') ==='1') ? 
    this._productDetailService.getProductDetail(id, this.pathnew).subscribe((data:any)=>{
     this.productDetailJson= data?.result?.data[0];
     this.vendorTypeInAdmin = data.result?.vedorDetails?.type;
     this.IdInVendorDetail = data.result?.vedorDetails?.id;
     this.suggested_effective_date = data?.result?.vedorDetails?.suggest_effective_date;
     this.slides.push(this.productDetailJson?.url)
     this.loadingData = true;
     this.hasRequest = data?.result?.data[0]?.has_vendor_product_request;
     this.hasmappedRequest= data?.result?.data[0]?.has_mapped_request;
     this.requestedPrice = this.productDetailJson?.latest_vendor_product_request 
     ?  this.productDetailJson?.latest_vendor_product_request?.price :  this.productDetailJson?.latest_vendor_mapped_product_request?.price;
     this.requestedUpdateAt = this.productDetailJson?.latest_vendor_product_request 
     ?  this.productDetailJson?.latest_vendor_product_request?.created_at :  this.productDetailJson?.latest_vendor_mapped_product_request?.created_at;
    })
    :
    this._productDetailService.getProductDetail(id).subscribe((data:any)=>{
      this.productDetailJson= data?.result?.data[0];
      this.IdInVendorDetail = data?.result?.vedorDetailsid;
      this.slides.push(this.productDetailJson?.url)
      this.loadingData = true;
      this.hasRequest = data?.result?.data[0]?.has_vendor_product_request;
     this.hasmappedRequest= data?.result?.data[0]?.has_mapped_request;
     this.requestedPrice = this.productDetailJson?.latest_vendor_product_request 
     ?  this.productDetailJson?.latest_vendor_product_request?.price :  this.productDetailJson?.latest_vendor_mapped_product_request?.price;
     this.requestedUpdateAt = this.productDetailJson?.latest_vendor_product_request 
     ?  this.productDetailJson?.latest_vendor_product_request?.created_at :  this.productDetailJson?.latest_vendor_mapped_product_request?.created_at;
     });

  }
  ngOnInit():void{
    this.activatedRoute.paramMap.subscribe((data:any)=>{
      this.path = Number(data.get('product_id'))
    })
    localStorage.getItem('roleId') ==='2' ? 
     this.productDetail(this.path) 
    :
    this.activatedRoute.parent?.paramMap.subscribe((data)=>{
      this.pathnew = Number(data.get('id'));
      // console.log(this.pathnew)
      this.productDetail(this.path)
    });
   
  }
  
}
