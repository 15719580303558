import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { api } from '../../../../environments/api_url';
import { io } from 'socket.io-client';
declare var window: any;
@Injectable({
  providedIn: 'root'
})
export class SocketInjectorService {

  constructor() { }

  socketConnect(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const socket = io('//localhost:1337');
      socket.on('notification', (data) => {
        // console.log(`New notification: ${data}`);
        // observer.next(data);
      })
    })
  }

  // handling with cors erros
  injectSocket(): Observable<any> {
    return new Observable((observer: Observer<any>) => {

      if (typeof window !== 'undefined') {
        window.laravel_echo_port = '6005';
      }


      const script = document.createElement('script');

      script.src = `${api.URL}:6005/socket.io/socket.io.js`;
      // script.src = 'https://staging-api.bilddit.com:6005/socket.io/socket.io.js';
      document.head.appendChild(script);
      script.async = true;
      script.defer = true;

      script.onload = () => {
        const laravelEcho = document.createElement('script');
        laravelEcho.src = `${api.URL}/js/app.js`;
        // laravelEcho.src = 'https://staging-api.bilddit.com/js/app.js';
        document.head.appendChild(laravelEcho);
        laravelEcho.async = true;
        laravelEcho.defer = true;

        laravelEcho.onload = () => {
          // console.log('Socket Injected');
          window.Echo.channel('builddit-channel')
            .listen('.BroadcastEvent', (data: any) => {
              observer.next(data);
            });
        };

        document.head.appendChild(laravelEcho);
      };
    });
  }
}
