import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '../../../../environments/api_url';

@Injectable({
  providedIn: 'root'
})
export class PendingProductListService {

  constructor(
    private http :HttpClient,

  ) { }

  pendingProducts(getPayload : any){
    const url = api.URL + '/api/vendor-pending-product';
    let payload ={
      vendor_id : getPayload.vendor_id,
      keyword: getPayload.keyword,
      page: getPayload.page,
    }
    return this.http.post(url,payload);
  }
  addSingleProduct(getPayload : any){
    const url = api.URL + '/api/single-product-request';
    let payload ={
      price : getPayload.price,
      product_id: getPayload.product_id,
      sku : getPayload.sku,
      erp_tsi_code: getPayload.erp_tsi_code,
    }
    return this.http.post(url,payload);
  }
}
