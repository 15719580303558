import { Component, Output,EventEmitter } from '@angular/core';
import { PendingProductList } from '../../interfaces/vendor-data';
import { PendingProductListService } from '../../core/services/adding-single-product/pending-product-list.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-adding-single-product',
  templateUrl: './adding-single-product.component.html',
  styleUrls: ['./adding-single-product.component.scss']
})

export class AddingSingleProductComponent {
  @Output () newItemEvent = new EventEmitter <string>();
  constructor(
    private _PendingProductListService :PendingProductListService,
    public _snackBar: MatSnackBar,
  ){}
  productListModalStatus :boolean = false;

  selectedProduct! :PendingProductList | null;
  loadMoreStatus = false;
  
  productListStatus = true;
  allPendingProductList!: PendingProductList[];
  pendingProductList : PendingProductList[] =[];

  passEventToParent(value: string) {
    this.newItemEvent.emit(value);
  }
  searchProductText ="" ;
  modaltoggle(){
    this.errorMessage =""
    this.productListModalStatus ? (this.productListModalStatus = false) : (this.productListModalStatus = true);
    this.pendingProductList = [];
    this.payload.keyword ="";
    this.selectedProduct = null; 
    this.searchProductText = "";
  }
 

  mapPendingList(list :any[]): PendingProductList[]{
    return list.map((item:any)=>{
      return({
        product_id: item?.magento_id,
        sku : item?.sku,
        erp_tsi_code :item?.erp_tsi_code,
        product_title: item?.name,
        product_url : item?.url,
      })
    })
  }
  payload = {
    vendor_id: Number(localStorage.getItem('hashid')) ,
    keyword: "",
    page: 1,

  }

  timeID : any;
  updateList(){
    this.productListStatus = false;
    this.pendingProductList = [];
    if (this.timeID){
      clearTimeout(this.timeID)
    }
    this.timeID = setTimeout(()=>{
      this.pendingList()
    }, 500)
    
  }
  lastpage = 1;
  loadMorePage =1;
  pendingList(pageNumber ?: number){
    if (!this.loadMoreStatus){
      this.productListStatus = false;
      this.pendingProductList = [];
    }

    (pageNumber && pageNumber <= this.lastpage) ? this.payload.page = pageNumber : (this.loadMorePage =1, this.payload.page = 1);

    if(pageNumber){
      this._PendingProductListService.pendingProducts(this.payload).subscribe((data :any)=>{
      if ( this.payload.keyword.length > 0)
      {
        let NewList = this.mapPendingList(data.result.data)
        this.pendingProductList = [...this.pendingProductList, ...NewList]

      } 
      else{
        this.pendingProductList = []
      }
      
        this.lastpage =data.result?.last_page
        this.productListStatus = true;
        this.loadMoreStatus = false;
      })
    }
    else{
      this._PendingProductListService.pendingProducts(this.payload).subscribe((data :any)=>{
        this.payload.keyword.length > 0 ? 
        (this.pendingProductList = this.mapPendingList(data.result.data))
         : (this.pendingProductList = [] )
        this.lastpage =data.result?.last_page
        this.productListStatus = true;
    
      })
    }
   
  }

  radioChange(selectedProduct : any, list : any){
      if (selectedProduct && selectedProduct !=list ){
        selectedProduct.price =null;
        
      }
      
  }
  

  loadmore(){
    this.loadMoreStatus = true;
    this.loadMorePage ++
    this.pendingList(this.loadMorePage)
  }
  errorMessage : string = "";
  addProductToList(){
    this.errorMessage ="";
    if (!this.selectedProduct){
      this.errorMessage = "A product needs to be selected, cannot submit empty form";
      return
    }
    else if (!this.selectedProduct.price){
      this.errorMessage = "Product price cannot be empty";
      return
    }
    this._PendingProductListService.addSingleProduct(this.selectedProduct).subscribe((data :any)=>{
      this._snackBar.open(data.message, "", {
        duration: 2000, verticalPosition: 'top', horizontalPosition: 'end',
        panelClass: ['snackbar_green']
      })
      this.passEventToParent("sucess from child")
    })
    this.modaltoggle();
    
  }
  ngOnInit(){
    // console.log(this.payload.vendor_id)
  }
}
