import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlutterService {
  constructor() { }
  async triggerFunction(token: string) {
    try {
      return this.callFlutterHandler('loginMSG', token);
    } catch (error) {
      console.error('Error triggering function in Flutter:', error);
    }
  }

  async triggerDownloadFunction(payload: { payload: { file_url: string; file_name: string; }; }) {
    try {
      return this.callFlutterHandler('DownloadFile', payload);
    } catch (error) {
      console.error('Error triggering function in Flutter:', error);
    }
  }
  private callFlutterHandler(key: string, data: any) {    
    if ((window as any).flutter_inappwebview) {
      // console.log("data if");      
      return (window as any).flutter_inappwebview.callHandler(key, data);
    } 
  }
}
