import { Injectable } from '@angular/core';
// If you import a module but never use any of the imported values other than as TypeScript types,
// the resulting javascript file will look as if you never imported the module at all.
import { ipcRenderer, webFrame } from 'electron';
import * as childProcess from 'child_process';
import * as fs from 'fs';
import { Router } from '@angular/router';
import { FlutterService } from '../flutter/flutter.service';

@Injectable({
  providedIn: 'root'
})
export class ElectronService {
  ipcRenderer!: typeof ipcRenderer;
  webFrame!: typeof webFrame;
  childProcess!: typeof childProcess;
  fs!: typeof fs;

  isElectronApp = false;

  constructor(
    private router:Router,
    private _FlutterService:FlutterService,
  ) {
    // Conditional imports
    if (this.isElectron) {
      this.isElectronApp = true
      this.ipcRenderer = (window as any).require('electron').ipcRenderer;
      this.webFrame = (window as any).require('electron').webFrame;

      this.fs = (window as any).require('fs');

      this.childProcess = (window as any).require('child_process');
      this.childProcess.exec('node -v', (error, stdout, stderr) => {
        if (error) {
          console.error(`error: ${error.message}`);
          return;
        }
        if (stderr) {
          console.error(`stderr: ${stderr}`);
          return;
        }
        // console.log(`stdout:\n${stdout}`);
      });


      // ipcRenderer.send('show-notification',{
      //   title: "test",
      //   body: "test"
      // })

      // Notes :
      // * A NodeJS's dependency imported with 'window.require' MUST BE present in `dependencies` of both `app/package.json`
      // and `package.json (root folder)` in order to make it work here in Electron's Renderer process (src folder)
      // because it will loaded at runtime by Electron.
      // * A NodeJS's dependency imported with TS module import (ex: import { Dropbox } from 'dropbox') CAN only be present
      // in `dependencies` of `package.json (root folder)` because it is loaded during build phase and does not need to be
      // in the final bundle. Reminder : only if not used in Electron's Main process (app folder)

      // If you want to use a NodeJS 3rd party deps in Renderer process,
      // ipcRenderer.invoke can serve many common use cases.
      // https://www.electronjs.org/docs/latest/api/ipc-renderer#ipcrendererinvokechannel-args
    }
  }
  sendNotes(title: string, body: string, event?: string) {
    if (this.isElectronApp) {
      this.ipcRenderer.send('show-notification', { title: title, body: body, orderData:event, icon: 'icon.png' });
      // this.ipcRenderer.on('notification-clicked', (event, response) => {
      //   console.log(response, this.count); // Handle the response as needed
      //   this.count++
      // });
    }
  }

  sendDownloads(url:string, name:string){
    if (this.isElectronApp) {
      // console.log(url,name);
      this.ipcRenderer.send('download',{
        payload:{
          file_url:url,
          file_name:name
        }
      })
      
    } else {
      this.router.navigate([]).then(()=>window.open(url, '_blank'))
      this._FlutterService.triggerDownloadFunction({
          payload:{
          file_url:url,
          file_name:name
        }
      });
    }
  }

  

  



  get isElectron(): boolean {
    return !!(window && window.process && window.process.type);
  }
}
