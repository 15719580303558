import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SellingConfirmationService } from '../../core/services/product-selling-confirmation/selling-confirmation.service';
import { catchError, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-product-selling-confirmation',
  templateUrl: './product-selling-confirmation.component.html',
  styleUrls: ['./product-selling-confirmation.component.scss']
})
export class ProductSellingConfirmationComponent {
  @Input() sellingProductInfo ! : any;
  @Input() vendorID ! : any;
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() Suggested_effective_date ! : Date;
  passEventToParent(value: string) {
    this.newItemEvent.emit(value);
  }
  constructor(
    private  _sellingConfirmationService : SellingConfirmationService,
    private _snackBar :MatSnackBar
  ){

  }
  today = new Date();
  modalStatus = false;
  payload : any ={};
  modalToggle(){
    this.effectiveDate =null;
    this.isDateValidOnSubmit = false;
    this.payload.price = null;
    this.modalStatus ? ( this.modalStatus = false ) :  ( this.modalStatus = true )
    this.payload = this.sellingProductInfo;
    this.hasError = false;
  }
  hasError : boolean = false;
  effectiveDate !: Date | null;
  adminAppStatus = localStorage.getItem('roleId') ==='1';
  clearDate(){
    this.effectiveDate = null;
  }
  submitSellingInfo(){
    if (!this.effectiveDate  && localStorage.getItem('roleId') === '1' && !this.payload.price){
      this.isDateValidOnSubmit = true;
      this.hasError = true;
      return
    }
    else if (!this.payload.price){
      this.hasError = true;
      return
    }
    else if (!this.effectiveDate  && localStorage.getItem('roleId') === '1'){
      this.isDateValidOnSubmit = true;
      return
    }
    // console.log(this.payload , "data")

    this.hasError = false;
    this.productConfirmation();
   
  }
  effectiveDateSelection(event: any): any {
    const date = new Date(event);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; 
    const day = date.getDate(); 

     return `${year}-${month}-${day}`;
    
}
  dateValid = true;
  isDateValidOnSubmit = false;
  formatValidate($event: any) {
    // console.log($event);
    if ( $event){
      // console.log($event?.value);
      this.isDateValidOnSubmit = false; 
      this.dateValid = true
    }
    else{
      this.dateValid = false;
     
    }
  }
  productConfirmation(){
    
    if (this.effectiveDate){
      this.effectiveDate = this.effectiveDateSelection(this.effectiveDate)
    }
    if (!this.effectiveDate && localStorage.getItem('roleId') === '1'){
      this.isDateValidOnSubmit = true;
      return
    }
    localStorage.getItem('roleId') === '1' ? 
 
    this._sellingConfirmationService.sellingConfirmationRequestAdmin(this.payload, this.vendorID, this.effectiveDate).pipe(
      catchError(err => {
        throw (err)
      })
    ).subscribe({
      next: (data : any)=>
      {
        this.modalStatus = false;
        if (data.status === "success") {
          this._snackBar.open(data.message, "", {
            duration: 3200, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_green']
          })
          // console.log(data, "find out")
           this.passEventToParent("sucess from child")
        } else{
          this._snackBar.open(data.message, "", {
            duration: 3200, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_red']
          })
        }
        // console.log(data)
      },
      error : (err) =>{
        console.log(err)
      },
    })
    :
    this._sellingConfirmationService.sellingConfirmationRequestVendor(this.payload).pipe(
      catchError(err => {
        throw (err)
      })
    ).subscribe({
      next: (data : any)=>
      {
        // console.log(data);
        this.modalStatus = false;
        if (data.status === "success") {
          this._snackBar.open(data.message, "", {
            duration: 3200, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_green']
          })
          // console.log(data, "find out")
          this.passEventToParent("sucess from child")
        } else{
          this._snackBar.open(data.message, "", {
            duration: 3200, verticalPosition: 'top', horizontalPosition: 'end',
            panelClass: ['snackbar_red']
          })
        }
      },
      error : (err) =>{
        console.log(err)
      },
    })
    // console.log("vendor")
  }
  ngOnInit(){
    
  }
}
