import { Injectable } from '@angular/core';
import { api } from '../../../../environments/api_url';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {

  constructor(private http: HttpClient) { }

  resetPassword(oldPassword: string, password:string, passwordConfirmation:string) {
    const url = api.URL + '/api/change-password';
    const payload = {
      old_password: oldPassword,
      password:password,
      password_confirmation:passwordConfirmation,
    }
    let dataPayload = this.http.post(
      url,
      payload
    )
    return dataPayload
  }

  updateProfile(payload:object) {
    const url = api.URL + '/api/update-profile';
    let dataPayload = this.http.post(
      url,
      payload
    )
    return dataPayload
  }



}
